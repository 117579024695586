import React from "react";

interface GenericButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  iconComponent?: JSX.Element;
  buttonText: string;
  height?: string;
  width?: string;
  customClass?: string;
  type?: "button" | "submit" | "reset";
  isDisabled?: boolean;
}

const GenericButton: React.FC<GenericButtonProps> = ({
  iconComponent,
  buttonText,
  height = "h-12",
  width = "w-full",
  customClass,
  type = "button",
  isDisabled = false,
  ...rest
}) => {
  return (
    <button
      className={`${height} ${width} ${
        isDisabled && "cursor-not-allowed bg-gray-light text-coal-light"
      } outline-none ${customClass ? customClass : "flex flex-row items-center justify-center"}`}
      disabled={isDisabled}
      {...rest}>
      {iconComponent}
      <span>{buttonText}</span>
    </button>
  );
};

export default GenericButton;
