import React, { useState } from "react";
import {
  DialogHeader,
  DialogBody,
  DialogFooter,
  GenericDialog,
} from "components/common/dialogs/GenericDialog";
import { UpsellPositionType, UpSellProductType, UpSellVariantType } from "lib/types/upsell";
import { X } from "react-feather";
import { insertBeforeLastOccurrence } from "lib/utils/helpers";
import Price from "components/common/Price";
import { useLocale } from "lib/hooks/useLocale";
import PrimaryButton from "components/common/buttons/PrimaryButton";
import { Minus, Plus } from "react-feather";
import { useCheckoutContext } from "lib/contexts/CheckoutProvider";
import { postRequest } from "lib/core/apiClient";
import { errorToast, successToast } from "lib/utils/toasters";
import { getBillingDetails, getItems } from "lib/utils/checkout";
import { ShippingHandlesType } from "lib/types/checkout";
import { mutate } from "swr";
import OverlaySpinner from "components/common/loaders/OverlaySpinner";
import blockImage from "assests/images/block-image.png";
import { useAuthContext } from "lib/contexts/AuthProvider";
import { useMerchantContext } from "lib/contexts/MerchantProvider";
import { useUserContext } from "lib/contexts/UserProvider";
import useSendAnalyticsEvent from "lib/hooks/useAnalytics";
import { analyticsEvents, analyticsTypes } from "lib/utils/constants";

interface UpSellVariantSelectorDialogProps {
  upsellProduct: UpSellProductType | undefined;
  isVariantSelectorOpen: boolean;
  closeVariantSelector: () => void;
  position: UpsellPositionType;
}

const UpSellVariantSelectorDialog: React.FC<UpSellVariantSelectorDialogProps> = ({
  upsellProduct,
  isVariantSelectorOpen,
  closeVariantSelector,
  position,
}) => {
  const { t } = useLocale();
  const {
    state: { checkoutId, checkoutView, billing, initialCheckoutStep, checkoutValidations },
    actions: {
      setCheckoutView,
      setCheckoutModal,
      setShippingHandles,
      updateCheckoutBasedOnCheckoutResponse,
      setCheckoutValidations,
    },
  } = useCheckoutContext();
  const {
    state: { isAuthenticated },
  } = useAuthContext();
  const {
    state: { merchant },
  } = useMerchantContext();
  const {
    state: { user },
  } = useUserContext();
  const { sendAnalyticsEvent } = useSendAnalyticsEvent();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [itemsData, setItemsData] = useState<Record<string, number>>({});

  const handleEditProduct = (variantId: string, quantity: number) => {
    const tempData = { ...itemsData };
    if (quantity < 1) {
      delete tempData[variantId];
      setItemsData(tempData);
      return;
    }
    tempData[variantId] = quantity;
    setItemsData(tempData);
  };

  const handleClose = () => {
    setItemsData({});
    closeVariantSelector();
  };

  const submitSelection = async () => {
    if (!Boolean(Object.keys(itemsData)?.length)) {
      closeVariantSelector();
      return;
    }
    const payload = {
      items: Object.keys(itemsData)?.map((key: string, index: number) => {
        return {
          variant_id: key,
          quantity: itemsData[key],
        };
      }),
    };

    try {
      setIsLoading(true);
      const url = Boolean(isAuthenticated)
        ? `/checkout/v1/checkout/${checkoutId}/upsell`
        : `/v1/checkout/${checkoutId}/upsell`;
      const response = await postRequest(url, payload, isAuthenticated ? "CHECKOUT" : "CHECKOUT_PUBLIC");
      if (!response) {
        errorToast(t("unable_to_add"));
        return;
      }
      sendAnalyticsEvent({
        eventName: analyticsEvents.UPSELL_PRODUCT_ADDED,
        eventType: "click",
        eventFor: [analyticsTypes.SF_ANALYTICS],
        context: "checkout",
        metaData: {
          upsellData: {
            position: position,
            product_id: upsellProduct?.productId,
            upsell_section: "generic",
            group_ids: upsellProduct?.upsellIds,
            upsold_items: Object.keys(itemsData)?.map((key: string, index: number) => {
              const selectedVariant = upsellProduct?.variants?.find(
                (variant: UpSellVariantType) => variant?.variantId === key,
              );
              return {
                product_id: upsellProduct?.productId,
                variant_id: key,
                quantity: itemsData[key],
                item_total: selectedVariant?.currentPrice,
              };
            }),
          },
          checkoutItems: getItems(response?.items),
        },
      });
      closeVariantSelector();
      successToast(
        t(
          Boolean(payload?.items?.length) || payload?.items?.[0]?.quantity > 1
            ? "upsell_multiple_product_added"
            : "upsell_product_added",
        ),
      );

      const parsedBilling = getBillingDetails(response.pricing) ?? {
        total_payable: 0,
        sub_total: 0,
        total: 0,
        tax: 0,
        rewards: 0,
        discount: 0,
        shipping: 0,
        cod: 0,
        cod_enabled: false,
        taxes_included: true,
        serviceable: true,
        prepaid_total: 0,
        prepaid_discount: 0,
        original_sub_total: 0,
      };
      if (billing.shipping !== parsedBilling.shipping) {
        successToast(t("shipping_fee_updated"));
      }
      const isAddressServiceable = response?.pricing?.serviceable ?? false;
      if (!Boolean(isAddressServiceable)) {
        if (Boolean(checkoutView === "PAYMENTS") && initialCheckoutStep !== "PAYMENTS") {
          setCheckoutView("ADDRESS_LIST");
        }
        if (Boolean(checkoutView === "PAYMENTS") && initialCheckoutStep === "PAYMENTS") {
          setCheckoutValidations({
            ...checkoutValidations,
            address: {
              isValid: false,
            },
          });
          setCheckoutModal("ADDRESS_LIST");
        }
        errorToast(t("serviceability_error"), 5000);
        return;
      }

      updateCheckoutBasedOnCheckoutResponse(response);

      const shippingHandles = response?.metadata?.available_shipping_handles;
      const showShippingHandles = response?.metadata?.show_shipping_handle_selector ?? false;
      setShippingHandles(shippingHandles as ShippingHandlesType);
      if (Boolean(showShippingHandles) && checkoutView === "PAYMENTS" && initialCheckoutStep !== "PAYMENTS") {
        setCheckoutModal("SHIPPING_HANDLES");
      } else {
        mutate(`/checkout/v2/checkout/${checkoutId}/payments`);
        Boolean(isAuthenticated)
          ? mutate(`/checkout/v1/checkout/${checkoutId}/upsell`)
          : mutate(`/v1/checkout/${checkoutId}/upsell`);

        Boolean(isAuthenticated)
          ? mutate(`/checkout/v1/checkout/${checkoutId}/discounts`)
          : mutate(`/v1/checkout/${checkoutId}/discounts`);

        mutate(`UPI_INTENT`);
      }
    } catch (e: any) {
      const errorMsg = e?.response?.data?.error;
      errorToast(errorMsg ?? t("coupon_not_found"));
    } finally {
      setIsLoading(false);
    }
  };

  if (!upsellProduct) return null;
  return (
    <>
      <GenericDialog
        isOpen={isVariantSelectorOpen}
        modalType="UPSELL_VARIANT_SELECTOR"
        dialogOverlay={true}
        translateAxis="y"
        customClass={`overflow-scroll md:!top-auto md:absolute h-full rounded-t-2xl max-h-[50vh]`}>
        <DialogHeader className="!bg-white !px-4">
          <div className="flex h-full w-full flex-row items-center justify-between py-[1.125rem]">
            <div className="flex flex-row items-center justify-start gap-2">
              <h1 className="text-base font-medium"> {upsellProduct?.name} </h1>
            </div>
            <X className="h-5 w-5 cursor-pointer" onClick={handleClose} />
          </div>
        </DialogHeader>
        <DialogBody className="scrollbar-hide pb-20 pt-12">
          <ul className="flex flex-col space-y-2 px-3">
            {upsellProduct?.variants?.map((variant: UpSellVariantType, index: number) => {
              const itemQty = itemsData[variant?.variantId];
              const isAddUnavailable =
                variant?.inventoryPolicy === "DENY" && variant?.inventoryQuantity <= itemQty;
              const getPercentageDiscount = () => {
                if (variant.isDiscountedUpsell && variant.discountedPrice) {
                  return ((variant.currentPrice - variant.discountedPrice) * 100) / variant.currentPrice;
                }

                return 0;
              };

              const totalPrice =
                Boolean(variant.isDiscountedUpsell) && variant.discountedPrice
                  ? variant.discountedPrice
                  : variant.currentPrice;

              return (
                <li key={`variant-${index + 1}`} className="flex w-full snap-start flex-col gap-3 py-2 ">
                  <div className="flex flex-row space-x-2">
                    <img
                      src={insertBeforeLastOccurrence(variant?.imageLink ?? blockImage, ".jpg", "_small")}
                      alt="Cart Item"
                      className={`h-[5rem] min-h-[5rem] w-[5rem] min-w-[5rem] rounded-lg border border-gray-light object-cover`}
                    />
                    <div className="flex w-full flex-col space-y-1">
                      <div className="flex flex-col space-y-1">
                        <p className="mt-1 text-sm font-medium text-coal-dark">{upsellProduct?.name}</p>
                        <div className="flex flex-row space-x-1 text-xs font-medium text-coal-dark">
                          <span>{variant?.name}</span>
                        </div>
                      </div>
                      <Price total={totalPrice} compareAt={variant.currentPrice} orientation="horizontal" />
                      {variant.isDiscountedUpsell && Boolean(Math.round(getPercentageDiscount())) && (
                        <span className="text-sm font-medium text-yay-dark">
                          {Math.round(getPercentageDiscount())}% off
                        </span>
                      )}
                    </div>
                    <div className="flex flex-col items-center justify-center">
                      {Boolean(itemQty) ? (
                        <div className="flex flex-row items-center justify-between space-x-1.5 rounded-md border-2 border-primary-dark px-2.5 py-1">
                          <button onClick={() => handleEditProduct(variant?.variantId, itemQty - 1)}>
                            <Minus className="h-4 w-4" />
                          </button>
                          <label className="min-w-[1rem] text-center text-sm">{itemQty}</label>
                          <button
                            disabled={isAddUnavailable}
                            onClick={() => handleEditProduct(variant?.variantId, itemQty + 1)}
                            className={`${isAddUnavailable ? "text-gray-light" : "text-coal-dark"}`}>
                            <Plus className="h-4 w-4" />
                          </button>
                        </div>
                      ) : (
                        <button
                          className="rounded-md bg-primary-dark px-4 py-1 text-btnPrimaryText"
                          onClick={() => handleEditProduct(variant?.variantId, 1)}>
                          {t("add")}
                        </button>
                      )}
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        </DialogBody>

        <DialogFooter>
          <PrimaryButton
            buttonText={t("confirm")}
            id="flo_upsell_variant_select"
            onClick={submitSelection}
            height="h-14"
            isCheckout={false}
          />
        </DialogFooter>
      </GenericDialog>

      {isLoading && <OverlaySpinner />}
    </>
  );
};

export default UpSellVariantSelectorDialog;
