import { regexPatterns } from "lib/utils/validations";

export const formatExpirationDate = (value: string) => {
  return value
    .replace(
      /[^0-9]/g,
      "", // To allow only numbers
    )
    .replace(
      /^([2-9])$/g,
      "0$1", // To handle 3 > 03
    )
    .replace(
      /^(1{1})([3-9]{1})$/g,
      "0$1/$2", // 13 > 01/3
    )
    .replace(
      /^0{1,}/g,
      "0", // To handle 00 > 0
    )
    .replace(
      /^([0-1]{1}[0-9]{1})([0-9]{1,2}).*/g,
      "$1/$2", // To handle 113 > 11/3
    );
};

export const currencyFormatter = (
  price: number | string = 0,
  showCurrencySymbol = true,
  options = { locale: "en-IN", currency: "INR" },
) => {
  const formatter = new Intl.NumberFormat(options.locale, {
    style: "currency",
    currency: options.currency,
  });
  const formattedPrice = formatter.format(+price);

  const finalPrice = Number.isInteger(Number(price))
    ? formattedPrice.slice(0, formattedPrice.length - 3)
    : formattedPrice;

  return showCurrencySymbol ? finalPrice : finalPrice.slice(1);
};

/**
 * formats a string input to a valid zip code
 *
 * @param  {string} value       The value to be formatted
 * @param  {object} values      The collection of values to check the country code from
 * @return {string}             Returns the formatted value
 */
export const formatZip = (value: string, values: any): string => {
  if (values?.country_code !== "IN") {
    return value.replace(regexPatterns.alphaNumericWithSpace, "");
  }
  if (regexPatterns.numeric.test(value)) {
    return value.replace(regexPatterns.numeric, "");
  }
  return value;
};
