import React, { useCallback, useEffect, useState } from "react";
import { useCheckoutContext } from "lib/contexts/CheckoutProvider";
import { useSearchParams } from "react-router-dom";

interface GenericBannerStripProps {
  bannerType?: string;
}

const GenericBannerStrip: React.FC<GenericBannerStripProps> = React.memo(({ bannerType }) => {
  const [searchParams] = useSearchParams();
  const page = searchParams.get("page")?.toLowerCase();
  const {
    state: { checkoutView, isC2P, billing, checkoutUIMetadata },
  } = useCheckoutContext();

  const [isBannerEnabled, setIsBannerEnabled] = useState<boolean>(true);
  const [bannerHtmlStr, setBannerHtmlStr] = useState<string>("");
  const [bannerBg, setBannerBg] = useState<string>("");
  const [textColor, setTextColor] = useState<string>("");

  useEffect(() => {
    if (!checkoutUIMetadata || !checkoutView) return;
    setBannerStates();
  }, [checkoutUIMetadata, checkoutView, isC2P, billing]);

  const setBannerStates = useCallback(() => {
    const bannersConfig = checkoutUIMetadata?.bannerConfig;
    if (!Boolean(bannersConfig)) return;

    let htmlStr = "";
    let bgColor = "";
    let txtColor = "";
    let isEnabled = false;
    let type = Boolean(bannerType) ? bannerType : checkoutView;

    if (checkoutView === "ADDRESS_LIST" || page === "cart") {
      type = "ORDER_SUMMARY";
    }
    // if (isC2P) {
    //   type = "NA";
    // }

    const currentCheckoutViewBanner = bannersConfig?.find((banner) => banner.type === type);
    if (Boolean(currentCheckoutViewBanner)) {
      htmlStr = currentCheckoutViewBanner?.htmlString ?? "";
      bgColor = `${currentCheckoutViewBanner?.bagroundColor ?? "black"}`;
      txtColor = `${currentCheckoutViewBanner?.textColor ?? "white"}`;
      isEnabled = currentCheckoutViewBanner?.enabled ?? false;
    }

    setBannerHtmlStr(htmlStr);
    setBannerBg(bgColor);
    setTextColor(txtColor);
    setIsBannerEnabled(isEnabled);
  }, [checkoutUIMetadata, checkoutView, isC2P, billing]);

  useEffect(() => {
    document?.documentElement?.style?.setProperty("--flo-bannerstrip-bg-color", `${bannerBg}` ?? "#00000080");
    document?.documentElement?.style?.setProperty("--flo-bannerstrip-txt-color", `${textColor}` ?? "#FFFFFF");
  }, [bannerBg, textColor]);

  if (!isBannerEnabled || !Boolean(checkoutUIMetadata?.bannerConfig)) return <></>;
  return (
    <div
      className={`bannerStripClass sticky top-0 z-20 flex  min-h-[1.875rem]  w-full items-center justify-center px-3 py-2 text-center text-sm`}
      dangerouslySetInnerHTML={{ __html: bannerHtmlStr }}></div>
  );
});

export default GenericBannerStrip;
