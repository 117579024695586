import React, { createContext, memo, ReactElement, useContext, useState } from "react";

export interface TabRootProps {
  children: React.ReactNode;
  defaultValue: string;
}

export interface TabListProps extends React.HTMLAttributes<HTMLDivElement> {}

export interface TabContentProps extends React.HTMLAttributes<HTMLDivElement> {
  value: string;
}

interface TabsContextInterface {
  defaultValue: string;
  currentValue: string;
  setCurrentValue: (value: string) => void;
}

export interface TabTriggerProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  value: string;
  renderTrigger?: (props: { value: string; isSelected: boolean; onClick: () => void }) => ReactElement;
}

const TabsContext = createContext<TabsContextInterface>({
  defaultValue: "",
  currentValue: "",
  setCurrentValue() {},
});

export const useTabContext = () => {
  const context = useContext(TabsContext);
  if (!context) {
    throw new Error("useTabContext must be used within a Tabs Component");
  }
  return context;
};

const TabRoot = memo(({ children, defaultValue }: TabRootProps) => {
  const [currentValue, setCurrentValue] = useState(defaultValue);
  return (
    <TabsContext.Provider value={{ currentValue, defaultValue, setCurrentValue }}>
      {children}
    </TabsContext.Provider>
  );
});

const TabContent = memo(({ children, value }: TabContentProps) => {
  const { currentValue } = useTabContext();
  if (value !== currentValue) {
    return <></>;
  }
  return <>{children}</>;
});

const TabTrigger = memo(({ value, children, renderTrigger, ...triggerProps }: TabTriggerProps) => {
  const { setCurrentValue, currentValue } = useTabContext();
  const isSelected = currentValue === value;

  if (renderTrigger) {
    return renderTrigger({ value, isSelected, onClick: () => setCurrentValue(value) });
  }

  return (
    <button onClick={() => setCurrentValue(value)} {...triggerProps}>
      {children}
    </button>
  );
});

const TabList = memo(({ children, ...listProps }: TabListProps) => {
  return <div {...listProps}>{children}</div>;
});
export { TabRoot as Root, TabList as List, TabContent as Content, TabTrigger as Trigger };
