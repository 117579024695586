import { CfCustomPayment, CfDefaultPayment } from "lib/types/payments";
import { loadScript } from "lib/utils/helpers";
import axios from "axios";
import { constants } from "lib/utils/constants";

export const cashfreeUrls: { [key: string]: string } = {
  DEFAULT_DEV: "https://sdk.cashfree.com/js/ui/1.0.26/dropinClient.sandbox.js",
  DEFAULT_PROD: "https://sdk.cashfree.com/js/ui/1.0.26/dropinClient.prod.js",
  CUSTOM_DEV: "https://sdk.cashfree.com/js/core/1.0.26/bundle.sandbox.js",
  CUSTOM_PROD: "https://sdk.cashfree.com/js/core/1.0.26/bundle.prod.js",
  PAY_API_DEV: "https://sandbox.cashfree.com/pg/orders/pay",
  PAY_API_PROD: "https://api.cashfree.com/pg/orders/pay",
  DEFAULT_DEV_V3: "https://sdk.cashfree.com/js/ui/2.0.0/cashfree.sandbox.js",
  DEFAULT_PROD_V3: "https://sdk.cashfree.com/js/ui/2.0.0/cashfree.prod.js",
};

export const loadCfDefaultSDK = async (pgVersion: string) => {
  const ENV = import.meta.env.VITE_NODE_ENV;
  let sdkUrl = pgVersion === "v3" ? cashfreeUrls.DEFAULT_DEV_V3 : cashfreeUrls.DEFAULT_DEV;
  if (ENV === constants.ENV_PROD) {
    sdkUrl = pgVersion === "v3" ? cashfreeUrls.DEFAULT_PROD_V3 : cashfreeUrls.DEFAULT_PROD;
  }
  try {
    const response = await loadScript(sdkUrl, () => onCfDefaultSdkLoad());
    return response;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

const onCfDefaultSdkLoad = () =>
  new Promise((resolve, reject) => {
    try {
      (window as any).CfDefaultSdk = new (window as any).Cashfree();
      return resolve({});
    } catch (e) {
      throw reject(e);
    }
  });

export const loadCfCustomSDK = async () => {
  const ENV = import.meta.env.VITE_NODE_ENV;
  let sdkUrl = cashfreeUrls.CUSTOM_DEV;
  if (ENV === constants.ENV_PROD) {
    sdkUrl = cashfreeUrls.CUSTOM_PROD;
  }
  try {
    const response = await loadScript(sdkUrl, () => onCfCustomSdkLoad());
    return response;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

const onCfCustomSdkLoad = () =>
  new Promise((resolve, reject) => {
    try {
      (window as any).CfCustomSdk = (window as any).Cashfree;
      return resolve({});
    } catch (e) {
      throw reject(e);
    }
  });

type CFNetbankingBanks = {
  [key: number]: string;
};
export const cfNetbankingBanks: CFNetbankingBanks = {
  // 3003: "Axis Bank",
  3005: "Bank of Baroda : Retail Banking",
  3006: "Bank of India",
  3007: "Bank of Maharashtra",
  3009: "Canara Bank",
  3010: "Catholic Syrian Bank",
  3011: "Central Bank of India",
  3012: "City Union Bank",
  3016: "Deutsche Bank",
  3017: "DBS Bank Ltd",
  3018: "DCB Bank : Personal",
  3019: "Dhanlakshmi Bank",
  3020: "Federal Bank",
  // 3021: "HDFC Bank",
  3022: "ICICI Bank",
  3023: "IDBI Bank",
  3024: "IDFC FIRST Bank",
  3026: "Indian Bank",
  3027: "Indian Overseas Bank",
  3028: "IndusInd Bank",
  3029: "Jammu and Kashmir Bank",
  3030: "Karnataka Bank Ltd",
  3031: "Karur Vysya Bank",
  3032: "Kotak Mahindra Bank",
  3033: "Laxmi Vilas Bank : Retail Net Banking",
  3037: "Punjab & Sind Bank",
  3038: "Punjab National Bank : Retail Net Banking",
  3039: "RBL Bank",
  3040: "Saraswat Bank",
  3042: "South Indian Bank",
  3043: "Standard Chartered Bank",
  3044: "State Bank Of India",
  3052: "Tamilnad Mercantile Bank Ltd",
  3054: "UCO Bank",
  3055: "Union Bank of India",
  3058: "Yes Bank Ltd",
  3060: "Bank of Baroda : Corporate",
  3061: "Bank of India : Corporate",
  3062: "DCB Bank : Corporate",
  3064: "Lakshmi Vilas Bank : Corporate",
  3065: "Punjab National Bank : Corporate",
  3066: "State Bank of India : Corporate",
  3067: "Union Bank of India : Corporate",
  // 3071: "Axis Bank Corporate",
  3072: "Dhanlaxmi Bank Corporate",
  3073: "ICICI Corporate Netbanking",
  3074: "Ratnakar Corporate Banking",
  3075: "Shamrao Vithal Bank Corporate",
  3076: "Equitas Small Finance Bank",
  3077: "Yes Bank Corporate",
  3079: "Bandhan Bank: Corporate banking",
  3080: "Barclays Corporate: Corporate Banking : Corporate",
  3081: "Indian Overseas Bank Corporate",
  3083: "City Union Bank of Corporate",
  // 3084: "HDFC Corporate",
  3086: "Shivalik Bank",
  3087: "AU Small Finance",
  3088: "Bandhan Bank : Retail Net Banking",
  3041: "Shamrao Vitthal Co:operative Bank",
  3051: "Tamil Nadu State Co:operative Bank",
  3089: "Utkarsh Small Finance Bank",
  3090: "The Surat People’s Co:operative Bank Limited",
  3091: "Gujarat State Co:operative Bank Limited",
  3092: "HSBC Retail Netbanking",
  3102: "Jana Small Finance Bank",
  3094: "Andhra Pragathi Grameena Bank",
  3096: "Bassien Catholic Coop Bank",
  3098: "Capital Small Finance Bank",
  3100: "ESAF Small Finance Bank",
  3101: "Fincare Bank",
  3104: "Janata Sahakari Bank Ltd Pune",
  3103: "Jio Payments Bank",
  3105: "Kalyan Janata Sahakari Bank",
  3113: "Karnataka Gramin Bank",
  3107: "Karnataka Vikas Grameena Bank",
  3108: "Maharashtra Gramin Bank",
  3111: "NKGSB Co:op Bank",
  3110: "North East Small Finance Bank Ltd",
  3114: "RBL Bank Limited : Corporate Banking",
  3115: "SBM Bank India",
  3116: "Suryoday Small Finance Bank",
  3118: "Thane Bharat Sahakari Bank Ltd",
  3106: "The Kalupur Commercial Co:Operative Bank",
  3117: "The Sutex Co:op Bank Ltd",
  3119: "TJSB Bank",
  3122: "UCO Bank Corporate",
  3120: "Varachha Co:operative Bank Limited",
  3121: "Zoroastrian Co:Operative Bank Ltd",
};

type CFWallets = {
  [key: number]: string;
};
export const cfWallets: CFWallets = {
  4001: "FreeCharge",
  4002: "MobiKwik",
  4003: "Ola Money",
  4004: "Reliance Jio Money",
  4006: "Airtel Money",
  4007: "Paytm",
  // 4008: "Amazon Pay",
  4009: "PhonePe",
};

interface CustomPaymentPaylod {
  order_token: string;
  payment_method?: {
    [key: string]: any;
  };
}
export const initializeCfCustomPayment = async ({ order_token, method, methodPayload }: CfCustomPayment) => {
  const payload: CustomPaymentPaylod = {
    order_token: order_token,
    payment_method: {},
  };

  switch (method) {
    case "netbanking": {
      payload.payment_method = {
        netbanking: { channel: "link", netbanking_bank_code: parseInt(methodPayload?.bank) },
      };
      break;
    }

    case "card": {
      payload.payment_method = {
        card: {
          channel: "link",
          card_number: methodPayload?.card?.number?.toString()?.replace(/ /g, ""),
          card_holder_name: methodPayload?.card?.name?.toString(),
          card_expiry_mm: methodPayload?.card?.expiry_month?.toString(),
          card_expiry_yy: methodPayload?.card?.expiry_year?.toString(),
          card_cvv: methodPayload?.card?.cvv?.toString(),
        },
      };
      break;
    }

    case "app": {
      payload.payment_method = {
        app: {
          channel: "link",
          provider: methodPayload?.wallet,
          phone: methodPayload?.phoneNumber?.toString()?.substring(3, 13),
        },
      };
      break;
    }
  }
  const headers = {
    "Content-Type": "application/json",
  };
  const ENV = import.meta.env.VITE_NODE_ENV;
  let cashfreePayUrl = cashfreeUrls.PAY_API_DEV;
  if (ENV === constants.ENV_PROD) {
    cashfreePayUrl = cashfreeUrls.PAY_API_PROD;
  }
  try {
    const response = await axios.post(cashfreePayUrl, payload, {
      headers: headers,
    });
    return response?.data;
  } catch (e) {
    throw e;
  }
};

export const initializeCfDefaultPayment = async ({
  orderToken,
  paymentMode,
  brandThemeColor = "#5a31f4",
  onSuccessHandler,
  onFailureHandler,
  pgVersion,
}: CfDefaultPayment) => {
  const dropConfig = {
    components: [paymentMode],
    orderToken: orderToken,
    onSuccess: function (data: any) {
      onSuccessHandler(paymentMode, data);
    },
    onFailure: function (data: any) {
      onFailureHandler(paymentMode, data);
    },

    style: {
      backgroundColor: "#FFFFFF",
      color: brandThemeColor?.substring(0, 7),
      fontSize: "14px",
      errorColor: "#CB2711",
      theme: "light",
    },
  };
  try {
    if (pgVersion === "v3") {
      const cf = new (window as any).Cashfree(orderToken);
      cf.redirect();
      return;
    }
    const cashfree = (window as any).CfDefaultSdk;
    const paymentElement = document.getElementById("payment-form");
    cashfree.initialiseDropin(paymentElement, dropConfig);
  } catch (e) {
    throw e;
  }
};
