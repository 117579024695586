import React, { useRef, useEffect, useState, useMemo } from "react";
import { classNames, scrollListener } from "lib/utils/helpers";
import { ChevronLeft, ChevronRight } from "react-feather";
interface CarouselProps {
  count: number;
  children?: React.ReactNode;
  customCSS?: string;
  shouldRenderDots?: boolean;
}

const SCROLL_BY_AMOUNT = 220;

const Carousel: React.FC<CarouselProps> = ({ count, children, customCSS, shouldRenderDots = true }) => {
  const carouselRef = useRef<HTMLUListElement | null>(null);
  const [scrollProgress, setScrollProgress] = useState<number>(0);
  const [isMouseDown, setIsMouseDown] = useState<boolean>(false);

  const mouseCoords = useRef({
    startX: 0,
    scrollLeft: 0,
  });

  const handleDragStart = (e: React.MouseEvent) => {
    if (!carouselRef.current) return;
    const slider = carouselRef.current as HTMLElement;
    const startX = e.pageX - slider.offsetLeft;
    const scrollLeft = slider.scrollLeft;
    mouseCoords.current = { startX, scrollLeft };
    setIsMouseDown(true);
    document.body.style.cursor = "grabbing";
  };

  const handleDragEnd = () => {
    setIsMouseDown(false);
    if (!carouselRef.current) return;
    document.body.style.cursor = "default";
  };

  const handleDrag = (e: React.MouseEvent) => {
    if (!isMouseDown || !carouselRef.current) return;
    e.preventDefault();
    const slider = carouselRef.current as HTMLElement;
    const x = e.pageX - slider.offsetLeft;
    const walkX = (x - mouseCoords.current.startX) * 1.2;
    slider.scrollLeft = mouseCoords.current.scrollLeft - walkX;
  };

  const scrollBy = (scrollPixels: number) => {
    if (carouselRef.current) carouselRef.current.scrollLeft = carouselRef.current.scrollLeft + scrollPixels;
  };

  useEffect(() => {
    if (!carouselRef?.current) return;

    const element: HTMLElement = carouselRef?.current;

    element.addEventListener("scroll", () => scrollListener(element, 100, setScrollProgress), {
      passive: true,
    });
    return () => element.removeEventListener("scroll", () => scrollListener(element, 100, setScrollProgress));
  }, []);

  // const activeDot = useMemo(() => Math.floor((scrollProgress * count) / 110), [co]);
  const renderDots = useMemo(() => {
    const dots: JSX.Element[] = [];
    const activeDot = Math.floor((scrollProgress * (count - 1)) / 110);
    for (let index = 0; index < count - 1; index++) {
      dots.push(
        <div
          key={`Item-${index}`}
          onClick={() =>
            carouselRef.current ? (carouselRef.current.scrollLeft = SCROLL_BY_AMOUNT * index) : null
          }
          className={`h-2 w-2 cursor-pointer snap-center rounded-full ${
            index === activeDot ? "bg-primary-dark" : "bg-gray-light"
          }`}></div>,
      );
    }
    return dots;
  }, [count, scrollProgress]);

  return (
    <div className="w-full">
      <div className={classNames("relative", "mb-3")}>
        {carouselRef.current && carouselRef.current.scrollLeft > 100 && (
          <button
            onClick={() => scrollBy(-SCROLL_BY_AMOUNT)}
            className="absolute -left-4 top-[calc(50%-6px)] flex h-6 w-6 items-center justify-center rounded-full border-[1px] border-carbon-lighter bg-white text-coal-dark">
            <ChevronLeft className="h-4 w-4" />
          </button>
        )}
        <ul
          ref={carouselRef}
          onMouseDown={handleDragStart}
          onMouseUp={handleDragEnd}
          onMouseMove={handleDrag}
          onMouseLeave={handleDragEnd}
          className={`scrollbar-hide flex w-full snap-x snap-mandatory items-center space-x-3 overflow-x-scroll ${
            Boolean(customCSS) && customCSS
          }`}>
          {children}
        </ul>
        {count > 1 && (
          <button
            onClick={() => scrollBy(SCROLL_BY_AMOUNT)}
            className={`absolute -right-2 top-[calc(50%-6px)] flex h-6 w-6 items-center justify-center rounded-full border-[1px] border-carbon-lighter bg-white text-coal-dark ${
              carouselRef.current && !(carouselRef.current.scrollLeft < 228 * (count - 2) - 260)
                ? "hidden"
                : "block"
            }`}>
            <ChevronRight className="h-4 w-4" />
          </button>
        )}
      </div>
      {shouldRenderDots ? (
        <div className="flex snap-x snap-mandatory items-center justify-center space-x-1 p-4 pt-[10px]">
          {renderDots}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default React.memo(Carousel);
