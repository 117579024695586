import React, { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useLocale } from "lib/hooks/useLocale";
import GenericButton from "components/common/buttons/GenericButton";
import celebrationImg from "assests/images/celebration.svg";
import { AppliedCouponType } from "lib/contexts/CheckoutProvider";
import Price from "components/common/Price";
import { currencyFormatter } from "lib/utils/formatters";
import { useCheckoutContext } from "lib/contexts/CheckoutProvider";
import { useMerchantContext } from "lib/contexts/MerchantProvider";

interface CelebrationPopupProps {
  isOpen: boolean;
  closePopup: () => void;
  appliedCouponCode: string;
  incomingCouponCode: string;
}

const CelebrationPopup: React.FC<CelebrationPopupProps> = ({
  isOpen,
  closePopup,
  appliedCouponCode,
  incomingCouponCode,
}) => {
  const { t } = useLocale();
  const {
    state: { billing, appliedCoupons },
  } = useCheckoutContext();
  const {
    state: { merchant },
  } = useMerchantContext();

  const [open, setOpen] = useState<boolean>(true);
  const [currentCoupon, setCurrentCoupon] = useState<AppliedCouponType>();
  const [couponType, setCouponType] = useState<"INCOMING" | "APPLIED" | "MULTIPLE">("INCOMING");

  const freebieAppliedCelebrationsText = Boolean(
    merchant?.celebrationPopupConfig?.freebieAppliedCelebrationsText,
  )
    ? merchant?.celebrationPopupConfig?.freebieAppliedCelebrationsText
    : t("freebie_applied_celebrations_txt");
  const freeGiftsText = Boolean(merchant?.celebrationPopupConfig?.freeGiftsText)
    ? merchant?.celebrationPopupConfig?.freeGiftsText
    : t("free_gifts");

  useEffect(() => {
    if ((!appliedCouponCode || !incomingCouponCode) && !appliedCoupons?.length) return;
    if (appliedCouponCode) {
      const appliedCoupon = appliedCoupons.find(
        (coupon) => coupon.code.toLowerCase() === appliedCouponCode.toLowerCase(),
      );
      Boolean(appliedCoupon) && setCurrentCoupon(appliedCoupon);
      setCouponType("APPLIED");
      return;
    }
    if (incomingCouponCode) {
      const automaticPrepaidDiscounts = appliedCoupons.filter(
        (coupon) => coupon.autoApplied && coupon.isPrepaid,
      )?.length;

      if (appliedCoupons?.length - automaticPrepaidDiscounts > 1) {
        setCouponType("MULTIPLE");
        return;
      }
      const incomingCoupon = appliedCoupons.find(
        (coupon) => coupon.code.toLowerCase() === incomingCouponCode.toLowerCase(),
      );
      Boolean(incomingCoupon) && setCurrentCoupon(incomingCoupon);
      setCouponType("INCOMING");
      return;
    }
  }, [appliedCoupons, appliedCouponCode, incomingCouponCode]);

  useEffect(() => {
    setOpen(isOpen);
    setTimeout(() => closeCelebrationsPopup(), 3000);
  }, []);

  const getFreebiesCount = useCallback(() => {
    return appliedCoupons
      ?.filter((coupon: AppliedCouponType) => coupon?.isFreebie)
      ?.reduce((acc: number, coupon: AppliedCouponType) => coupon?.freebieItemCount + acc, 0);
  }, [appliedCoupons]);

  const rewardsAmount = useMemo(() => {
    return (
      appliedCoupons
        ?.filter((coupon: AppliedCouponType) => coupon.isReward)
        ?.reduce(
          (acc: number, coupon: AppliedCouponType) => (coupon?.couponRewardConfig?.amount ?? 0) + acc,
          0,
        ) ?? 0
    );
  }, [appliedCoupons]);

  const getAppliedDiscountTitle = useCallback(() => {
    if (couponType === "MULTIPLE")
      return (
        <>
          {Boolean(getFreebiesCount())
            ? freebieAppliedCelebrationsText
            : t("multiple_automatic_discounts_applied")}
        </>
      );
    return Boolean(getFreebiesCount()) && Boolean(billing?.discount) ? (
      <>{freebieAppliedCelebrationsText}</>
    ) : Boolean(billing?.discount) || Boolean(currentCoupon?.isPrepaid) ? (
      <>
        {Boolean(currentCoupon?.title) ? currentCoupon?.title : currentCoupon?.code}
        <span className="font-normal text-coal-light">&nbsp;{t("applied")}</span>
      </>
    ) : (
      <>{freebieAppliedCelebrationsText}</>
    );
  }, [couponType, currentCoupon, appliedCoupons]);

  const getAppliedDiscountDesc = useCallback(() => {
    if (couponType !== "APPLIED") return;
    if (!Boolean(billing?.discount)) return <></>;
    if (Boolean(currentCoupon?.isFreebie)) return <></>;

    return (
      <>
        {t("offer_applied_desc")} <span className="px-0.5"></span>
        <Price total={currentCoupon?.discountValue ?? ""} />
      </>
    );
  }, [currentCoupon, couponType]);

  const getTotalSavings = useCallback(() => {
    if (couponType === "APPLIED") {
      return (
        <p className="celebrations-savings_strip flex h-full w-full flex-row items-center justify-center gap-1.5 rounded-2xl text-sm font-medium">
          {Boolean(currentCoupon?.isPrepaid) ? (
            <>{t("prepaid_discount_text", { discount: currencyFormatter(currentCoupon?.prepaidValue) })}</>
          ) : Boolean(currentCoupon?.isFreebie) ? (
            <>{getFreebiesCount() > 1 ? t("x_free_gifts", { count: getFreebiesCount() }) : freeGiftsText}</>
          ) : (
            <>
              {t("celebrations_total_discounts_strip")}
              <Price total={billing?.discount + rewardsAmount} />
            </>
          )}
        </p>
      );
    }
    return (
      <p className="celebrations-savings_strip flex h-full w-full flex-row items-center justify-center gap-1.5 rounded-2xl text-sm font-medium">
        {Boolean(billing?.discount) && Boolean(getFreebiesCount()) ? (
          <>
            {t("free_gifts_and_savings")}
            <Price total={billing.discount ?? ""} />
          </>
        ) : Boolean(currentCoupon?.isPrepaid) ? (
          <>{t("prepaid_discount_text", { discount: currencyFormatter(currentCoupon?.prepaidValue) })}</>
        ) : Boolean(getFreebiesCount()) ? (
          <>{getFreebiesCount() > 1 ? t("x_free_gifts", { count: getFreebiesCount() }) : freeGiftsText}</>
        ) : (
          <>
            {t("celebrations_total_discounts_strip")}
            <Price total={billing.discount + rewardsAmount} />
          </>
        )}
      </p>
    );
  }, [currentCoupon, couponType]);

  const closeCelebrationsPopup = () => {
    setOpen(false);
    setTimeout(() => closePopup(), 400);
  };

  if (!appliedCouponCode && !incomingCouponCode) return <></>;

  return (
    <>
      <Transition appear show={open} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 z-40 overflow-y-auto" onClose={() => {}}>
          <div className="relative min-h-screen text-center">
            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-full"
              enterTo="opacity-100 translate-y-0"
              leave="ease-in duration-300"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-full">
              <div
                id="celebrations-wrapper"
                className={`celebrations-wrapper fixed inset-x-0 bottom-0 z-30 m-auto inline-block h-full w-full  text-left align-middle md:top-0 md:h-[85vh] md:w-[25rem] md:rounded-b-2xl`}
                onClick={closeCelebrationsPopup}>
                <div className="celebrations-text_bg absolute bottom-0 z-40 w-full rounded-b-inherit opacity-90">
                  <div className="flex w-full flex-col items-center pb-12 pt-4">
                    <img src={celebrationImg} alt="flo-logo-mono" />
                    <p className="w-[95%] break-words pt-4 text-center text-lg font-semibold text-carbon-dark">
                      {getAppliedDiscountTitle()}
                    </p>
                    <p className="flex pb-5 pt-1 text-sm text-coal-light">{getAppliedDiscountDesc()}</p>
                    <div className="flex h-11 w-full flex-col items-center justify-center px-5">
                      {getTotalSavings()}
                    </div>
                    <div className="pt-6">
                      <GenericButton
                        buttonText={
                          Boolean(merchant?.celebrationPopupConfig?.celebrationsTotalSavingsBtnText) &&
                          merchant?.celebrationPopupConfig?.celebrationsTotalSavingsBtnText
                            ? merchant?.celebrationPopupConfig?.celebrationsTotalSavingsBtnText
                            : t("celebrations_total_savings_btn_text")
                        }
                        customClass="text-yay-dark font-semibold"
                        onClick={closeCelebrationsPopup}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default CelebrationPopup;
