import React from "react";
import CircularSpinner from "components/common/loaders/CircularSpinner";
import PaymentGateways from "components/common/PaymentGateways";
import TrustBadgeGray from "assests/images/trust-badge-gray.svg";
import { useLocale } from "lib/hooks/useLocale";
import useSendAnalyticsEvent from "lib/hooks/useAnalytics";
import { analyticsEvents } from "lib/utils/constants";
import { classNames } from "lib/utils/helpers";

interface ButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  type?: "button" | "submit" | "reset";
  buttonText?: string;
  height?: string;
  width?: string;
  borderRadius?: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  appearance?: string;
  paymentGateways?: boolean;
  isCheckout?: boolean;
  showTerms?: boolean;
  hideButton?: boolean;
  showCustomTerms?: boolean;
  customTermsLink?: string;
  containerClassName?: string;
}

const PrimaryButton: React.FC<ButtonProps> = ({
  type = "button",
  buttonText = "Confirm",
  height = "h-14",
  width = "w-full",
  isDisabled = false,
  isLoading = false,
  appearance = "solid",
  borderRadius = "rounded-2xl",
  paymentGateways = false,
  isCheckout = false,
  showTerms = false,
  hideButton = false,
  showCustomTerms = false,
  customTermsLink,
  className,
  containerClassName,
  ...rest
}) => {
  const { t } = useLocale();
  const { sendAnalyticsEvent } = useSendAnalyticsEvent();

  return (
    <div className={classNames("flex w-full flex-col items-center space-y-2", containerClassName ?? "")}>
      {showCustomTerms && (
        <div>
          <a
            href={customTermsLink}
            className="text-xxs font-normal text-coal-light underline"
            target="_blank"
            rel="noreferrer">
            {t("terms_and_conditions_loyalty")}
          </a>
        </div>
      )}
      <button
        className={classNames(
          `flex min-h-[40px] flex-row items-center justify-center gap-2 space-x-4 whitespace-nowrap`,
          isDisabled
            ? "cursor-not-allowed bg-gray-light text-coal-light"
            : "cursor-pointer bg-primary-dark text-btnPrimaryText",
          "px-4 text-base font-medium ",
          borderRadius,
          appearance === "outline" ? "border-2 border-primary-dark bg-white" : "",
          hideButton ? "hidden" : "",
          height,
          width,
          className ?? "",
        )}
        type={type}
        disabled={isLoading || isDisabled}
        {...rest}>
        {isLoading && <CircularSpinner color={`${isDisabled ? "text-white" : "text-btnPrimaryText"}`} />}
        {buttonText}
      </button>

      {isCheckout && paymentGateways && <PaymentGateways />}

      {showTerms && (
        <>
          <p className="pt-1 text-[12px] font-normal text-coal-light">
            {t("by_proceeding_text")}{" "}
            <a
              href="https://shopflo.com/terms-conditions?utm_source=shopflo-checkout&utm_medium=shopflo-checkout&utm_campaign=shopflo-checkout"
              className="font-bold"
              target="_blank"
              rel="noreferrer"
              onClick={() => {
                sendAnalyticsEvent({
                  eventName: analyticsEvents.FLO_TERMS_AND_CONDITIONS,
                  eventType: "click",
                });
              }}>
              {t("terms_and_conditions")}
            </a>{" "}
            and{" "}
            <a
              href="https://shopflo.com/privacy-policy?utm_source=shopflo-checkout&utm_medium=shopflo-checkout&utm_campaign=shopflo-checkout"
              className="font-bold"
              target="_blank"
              rel="noreferrer"
              onClick={() => {
                sendAnalyticsEvent({
                  eventName: analyticsEvents.FLO_PRIVACY_POLICY,
                  eventType: "click",
                });
              }}>
              {t("privacy_policy")}
            </a>
          </p>
        </>
      )}

      {isCheckout && (
        <div className="flex w-full flex-row items-center justify-center space-x-1">
          {/* <Lock className="w-3 h-3 text-coal-light" strokeWidth={3} />
          <span className="text-[10px] font-normal text-coal-light">Secured by </span> */}
          {/* <a href="https://shopflo.com" className="font-bold" target="_blank" rel="noreferrer">
            <img src={ShopfloLogo} className="h-3" alt={"shopflo-logo"} />
          </a> */}
          <img src={TrustBadgeGray} className="h-4" alt={"shopflo-logo"} />
        </div>
      )}
    </div>
  );
};

export default PrimaryButton;
