import React, { useCallback } from "react";
import { DialogHeader, DialogBody } from "components/common/dialogs/GenericDialog";
import { XIcon } from "@heroicons/react/solid";
import { useLocale } from "lib/hooks/useLocale";
import PrimaryButton from "components/common/buttons/PrimaryButton";
import { useCheckoutContext } from "lib/contexts/CheckoutProvider";

interface RemoveItemDialogProps {
  itemName: string;
  setIsOpen: () => void;
  onConfirm: (isLastItem: boolean) => void;
  isCartItem?: boolean;
}

const RemoveItemDialog: React.FC<RemoveItemDialogProps> = React.memo(
  ({ itemName, setIsOpen, onConfirm, isCartItem = false }) => {
    const { t } = useLocale();

    const {
      state: { checkoutItems },
    } = useCheckoutContext();

    const isLastItem = useCallback(() => {
      const checkoutItemsLength = checkoutItems?.length;
      if (checkoutItemsLength <= 1) {
        return true;
      }
      let nonFreebieCount = 0;
      checkoutItems.forEach((checkoutItem) => {
        if (!checkoutItem.is_freebie) {
          nonFreebieCount++;
        }
      });
      if (nonFreebieCount === 1) {
        return true;
      }
      return false;
    }, [checkoutItems]);

    return (
      <>
        <DialogHeader>
          <div className="flex h-full w-full flex-row items-center justify-between">
            <h1 className="text-base font-medium text-carbon-dark">{t("remove_item_dialog_header")}</h1>
            <button className="outline-none">
              <XIcon className="h-6 w-6 cursor-pointer text-coal-dark" onClick={() => setIsOpen()} />
            </button>
          </div>
        </DialogHeader>

        <DialogBody>
          <div className="relative flex w-full flex-col space-y-6 px-6 pb-4">
            <p className="text-sm text-coal-dark">{t("remove_item_dialog_desc", { item_name: itemName })}</p>
            <PrimaryButton
              buttonText={
                Boolean(isLastItem())
                  ? isCartItem
                    ? t("remove_last_cart_item_dialog_cta")
                    : t("remove_last_item_dialog_cta")
                  : t("remove_item_dialog_cta")
              }
              onClick={() => onConfirm(isLastItem())}
              height="h-14"
              isLoading={false}
              isDisabled={false}
            />
          </div>
        </DialogBody>
      </>
    );
  },
);

export default RemoveItemDialog;
