export type AxiosErrorType = {
  errorText: string;
  status: number;
};

export type FirebaseErrorType = {
  code: string;
};

interface FilterConditionBase {
  component: string;
}

export interface FilterConditionAxios extends FilterConditionBase {
  isAxiosError: boolean;
  condition: AxiosErrorType;
}

export interface FilterConditionFirebase extends FilterConditionBase {
  isFirebaseError: boolean;
  condition: FirebaseErrorType;
}

export type FilterConditionType = FilterConditionAxios | FilterConditionFirebase;

export const sentryFilterConditions: FilterConditionType[] = [
  {
    component: "discounts",
    isAxiosError: true,
    condition: {
      status: 400,
      errorText: "Discount code is invalid",
    },
  },
  {
    component: "firebase-auth",
    isFirebaseError: true,
    condition: {
      code: "invalid-verification-code",
    },
  },
  {
    component: "address-pincode",
    isAxiosError: true,
    condition: {
      status: 404,
      errorText: "Pincode is not found",
    },
  },
  {
    component: "address-email",
    isAxiosError: true,
    condition: {
      status: 400,
      errorText: "Email Invalid",
    },
  },
];
