import { AnalyticsCartLineItems, AnalyticsCartType } from "lib/types/analytics";

export const getCartForAnalytics = (lineItems: any, billing: any) => {
  const items: AnalyticsCartLineItems[] = [];
  lineItems?.forEach((item: any) =>
    items.push({
      item_id: item.variant_id,
      price: item.price,
      product_id: item.product_id,
      quantity: item.quantity,
      title: item.item_title,
      variant_id: item.variant_id,
    }),
  );
  const cart: AnalyticsCartType = {
    currency: "INR",
    line_items: items,
    total_price: billing?.total_payable ?? 0,
  };
  return cart;
};
