import { UserType } from "lib/types/user";
import { getAddressById, formatAllAddresses, hasAddressType } from "lib/utils/address";

/**
 * Takes accountResponse and checkoutResponse data. Parses and converts them to user type data.
 *
 * @param  {object} accountResponse       Account Attributes data response
 * @param  {object} checkoutResponse      Checkout data response. Used for default addresses in this context
 *
 * @return {object} userData   Parsed User data in the type of UserType
 */
export const parseUserData = (accountResponse: any, checkoutResponse: any) => {
  const areAdressesSame =
    checkoutResponse?.addresses?.shipping_address_id === checkoutResponse?.addresses?.billing_address_id;

  let shippingAddress;
  let billingAddress;

  shippingAddress = getAddressById(
    checkoutResponse?.addresses?.shipping_address_id,
    accountResponse?.addresses,
  );

  if (!areAdressesSame) {
    billingAddress = getAddressById(
      checkoutResponse?.addresses?.billing_address_id,
      accountResponse?.addresses,
    );
  }

  const userData: UserType = {
    uid: accountResponse?.uid,
    email: accountResponse?.account_attributes?.email,
    phone: accountResponse?.phone,
    name: accountResponse?.account_attributes?.attributes?.name,
    addresses: formatAllAddresses(accountResponse?.addresses, shippingAddress?.id ?? ""),
    default_billing_address: areAdressesSame ? shippingAddress : billingAddress,
    default_shipping_address: shippingAddress,
  };

  return userData;
};
