// @ts-nocheck
const ENV = import.meta.env.VITE_NODE_ENV;
const clarityId = import.meta.env.VITE_CLARITY_ID;

function clarity() {
  var params = Array.prototype.slice.call(arguments);
  if (!window.clarity) {
    throw new Error("Clarity is not initialized");
  }

  window.clarity.apply(undefined, params);
}

export const initialiseClarity = async () => {
  if (ENV !== "production" || !Boolean(clarityId)) return;
  (function (c, l, a, r, i, t, y) {
    c[a] =
      c[a] ||
      function () {
        (c[a].q = c[a].q || []).push(arguments);
      };
    t = l.createElement(r);
    t.async = 1;
    t.src = "https://www.clarity.ms/tag/" + i;
    y = l.getElementsByTagName(r)[0];
    y.parentNode.insertBefore(t, y);
  })(window, document, "clarity", "script", clarityId);
};

function hasStarted() {
  return typeof window.clarity === "function";
}

function identify(userId, properties) {
  clarity("identify", userId, properties);
}

function consent() {
  clarity("consent");
}

function setTag(key, value) {
  clarity("set", key, value);
}

function upgrade(reason) {
  clarity("upgrade", reason);
}

export default { hasStarted, identify, consent, setTag, upgrade } as clarity;
