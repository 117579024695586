import React from "react";
import { ArrowLeft } from "react-feather";
import useSendAnalyticsEvent from "lib/hooks/useAnalytics";
import { analyticsEvents } from "lib/utils/constants";
import { inIframe, closeIframe } from "lib/utils/helpers";
import { useSearchParams } from "react-router-dom";

interface SkeletonProps {}

const Skeleton: React.FC<SkeletonProps> = ({}) => {
  const [searchParams] = useSearchParams();
  const isSsoLoginPage = searchParams.get("page") === "login-sso";
  const { sendAnalyticsEvent } = useSendAnalyticsEvent();

  const handleClose = () => {
    sendAnalyticsEvent({
      eventName: analyticsEvents.FLO_BACK_CLICK,
      eventType: "load",
    });
    if (inIframe()) {
      closeIframe(false);
      return;
    }
    (window as any).history.back();
  };
  if (isSsoLoginPage) {
    return (
      <div className="h-screen w-full touch-none bg-white">
        <main className="flex flex-col items-center space-y-4 px-3 pt-4">
          <div className="skeleton-loader h-6 w-full rounded-xl"></div>
          <div className="skeleton-loader h-40 w-full rounded-xl"></div>
          <div className="skeleton-loader h-4 w-2/3 rounded-xl"></div>
        </main>
      </div>
    );
  }

  return (
    <>
      <div className="h-screen w-full touch-none bg-white">
        {/* <header className="fixed z-20 flex h-16 w-full items-center px-6 shadow-sm md:w-[25rem]">
          <button className="flex items-center justify-center mr-2 cursor-pointer" onClick={handleClose}>
            <ArrowLeft className="w-6 h-6 text-coal-dark" />
          </button>
          <div className="rounded skeleton-loader h-9 w-28"></div>
        </header> */}
        <main className="px-6 pt-6 ">
          <section className="flex flex-row space-x-2">
            <div className="skeleton-loader h-4 w-4 rounded"></div>
            <div className="skeleton-loader h-4 w-1/2 rounded"></div>
          </section>
          <section className="pt-8">
            <ul className="flex flex-col space-y-6">
              {[1, 2, 3].map((e) => (
                <li className="flex flex-row space-x-4" key={e}>
                  <div className="skeleton-loader h-16 w-16 rounded"></div>
                  <div className="flex grow flex-col space-y-4">
                    <div className="skeleton-loader h-4 w-3/4 rounded"></div>
                    <div className="skeleton-loader h-4 w-1/2 rounded"></div>
                  </div>
                </li>
              ))}
            </ul>
          </section>
        </main>
      </div>
    </>
  );
};

export default Skeleton;
