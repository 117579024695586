import React, { useEffect, useState } from "react";
import CircularSpinner from "components/common/loaders/CircularSpinner";
import FloRipple from "components/common/loaders/FloRipple";
import { createPortal } from "react-dom";

interface OverlaySpinnerProps {
  spinnerComponent?: "CIRCULAR" | "FLO_LOGO";
}

const OverlaySpinner: React.FC<OverlaySpinnerProps> = ({ spinnerComponent = "FLO_LOGO" }) => {
  return (
    <OverlayPortal>
      <div className="fixed inset-0 z-[100] h-screen w-full">
        <div className="h-full w-full bg-black opacity-40"></div>
        <div className="fixed inset-0">
          {/* <CircularSpinner color={"text-brandPrimary"} customClass="" height={"h-8"} width={"w-8"} /> */}
          <FloRipple />
        </div>
      </div>
    </OverlayPortal>
  );
};

export default OverlaySpinner;

const OverlayPortal = ({ children }: { children: React.ReactElement }) => {
  let [mounted, setMounted] = useState(false);

  useEffect(() => setMounted(true), []);
  const portalWrapper = document.getElementById("flo-overlay-portal");

  if (!mounted || !portalWrapper) return null;
  return createPortal(children, portalWrapper);
};
