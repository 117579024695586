import { classNames } from "lib/utils/helpers";
import React, { useEffect, useMemo, useState } from "react";

type AllowedInputTypes = "password" | "text" | "number" | "tel";

interface OTPInputProps {
  OTPLength: number;
  disabled: boolean;
  autoFocus: boolean;
  hasError: boolean;
  value: string;
  onChange: (otp: string) => void;
  inputType?: AllowedInputTypes;
  containerCustomClass?: object;
  inputClassName?: string;
  disableTheme?: boolean;
}

const OTPInput = ({
  value = "",
  OTPLength = 4,
  onChange,
  autoFocus = false,
  inputType = "tel",
  hasError,
  inputClassName,
  disabled,
  disableTheme = false,
}: OTPInputProps) => {
  const [activeInput, setActiveInput] = useState(0);
  const inputRefs = React.useRef<Array<HTMLInputElement | null>>([]);
  const [isAutoFilled, setIsAutoFilled] = useState(true);

  const getOTPValue = () => (value ? value.toString().split("") : []);

  const isInputNum = inputType === "number" || inputType === "tel";

  const handleAutoFill = (event: Event) => {
    const target = event.target as HTMLInputElement;
    if (target && target.value && target.autocomplete === "one-time-code") {
      setIsAutoFilled(true);
      const otp = target.value.split("").slice(0, OTPLength);
      handleOTPChange(otp);
    }
  };

  useEffect(() => {
    inputRefs.current = inputRefs.current.slice(0, OTPLength);
    inputRefs.current.forEach((input) => {
      input?.addEventListener("input", handleAutoFill);
    });
    return () => {
      inputRefs.current.forEach((input) => {
        input?.removeEventListener("input", handleAutoFill);
      });
    };
  }, [OTPLength]);

  useEffect(() => {
    if (autoFocus) {
      inputRefs.current[0]?.focus();
    }
  }, [autoFocus]);

  const isInputValueValid = (value: string) => {
    const isTypeValid = isInputNum ? !isNaN(Number(value)) : typeof value === "string";
    return isTypeValid && value.trim().length === 1;
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const nativeEvent = event.nativeEvent as InputEvent;
    if (isInputValueValid(value)) {
      setIsAutoFilled(false);
      changeCodeAtFocus(value);
      focusInput(activeInput + 1);
    } else {
      // This was added previously to handle an edge case
      // for dealing with keyCode "229 Unidentified" on Android.
      if (nativeEvent.data === null && nativeEvent.inputType === "deleteContentBackward") {
        event.preventDefault();
        changeCodeAtFocus("");
        focusInput(activeInput - 1);
      }
    }
  };

  const handleFocus = (index: number) => (event: React.FocusEvent<HTMLInputElement>) => {
    setActiveInput(index);
    event.target.select();
  };

  const handleBlur = () => {
    setActiveInput(activeInput - 1);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const otp = getOTPValue();
    switch (event.key) {
      case "Backspace":
        event.preventDefault();
        changeCodeAtFocus("");
        focusInput(activeInput - 1);
        break;
      case "Delete":
        event.preventDefault();
        changeCodeAtFocus("");
        break;
      case "ArrowLeft":
        event.preventDefault();
        focusInput(activeInput - 1);
        break;
      case "ArrowRight":
        event.preventDefault();
        focusInput(activeInput + 1);
        break;
      case otp[activeInput]:
        // React does not trigger onChange when the same value is entered
        // again. So we need to focus the next input manually in this case.
        event.preventDefault();
        focusInput(activeInput + 1);
        break;
      case "Spacebar":
        event.preventDefault();
        break;
      case "Space":
        event.preventDefault();
        break;
      case "ArrowUp":
        event.preventDefault();
        break;
      case "ArrowDown":
        event.preventDefault();
        break;
      default:
        break;
    }
  };

  const focusInput = (index: number) => {
    const activeInput = Math.max(Math.min(OTPLength - 1, index), 0);

    if (inputRefs.current[activeInput]) {
      inputRefs.current[activeInput]?.focus();
      inputRefs.current[activeInput]?.select();
      setActiveInput(activeInput);
      setIsAutoFilled(activeInput >= 1 ? false : true);
    }
  };

  const changeCodeAtFocus = (value: string) => {
    const otp = getOTPValue();
    otp[activeInput] = value[0];
    handleOTPChange(otp);
  };

  const handleOTPChange = (otp: Array<string>) => {
    const otpValue = otp.join("");
    onChange(otpValue);
  };

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault();
    const otp = getOTPValue();
    let nextActiveInput = activeInput;
    // Get pastedData in an array of max size (num of inputs - current position)
    const pastedData = event.clipboardData
      .getData("text/plain")
      .slice(0, OTPLength - activeInput)
      .split("");
    // Prevent pasting if the clipboard data contains non-numeric values for number inputs
    if (isInputNum && pastedData.some((value) => isNaN(Number(value)))) {
      return;
    }
    // Paste data from focused input onwards
    for (let pos = 0; pos < OTPLength; ++pos) {
      if (pos >= activeInput && pastedData.length > 0) {
        otp[pos] = pastedData.shift() ?? "";
        nextActiveInput++;
      }
    }

    focusInput(nextActiveInput + pastedData.length);
    handleOTPChange(otp);
  };

  // Needs to be memorized
  const renderInputs = useMemo(() => {
    const inputs = [];
    for (let index = 0; index < 4; index++) {
      inputs.push(
        <input
          key={index}
          className={classNames(
            `box-border	h-[3.8rem] w-full  rounded-2xl border-[1px] border-solid text-center text-[1.5rem] text-carbon-dark
           focus:w-[calc(65%)] focus:border-[1px] focus:px-[16px] focus:outline-none focus:ring-[2px] `,
            disableTheme
              ? "focus:border-black focus:ring-[1px] focus:ring-black"
              : "focus:border-primary-dark focus:ring-primary-light",
            hasError ? "border-ouch" : "border-gray-light",
            inputClassName ?? "",
          )}
          id={`flo__auth__otpInputField${index + 1}`}
          value={getOTPValue()[index] ?? ""}
          ref={(element) => (inputRefs.current[index] = element)}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          onFocus={handleFocus(index)}
          onBlur={handleBlur}
          type={"tel"}
          onPaste={handlePaste}
          disabled={disabled}
          autoFocus={index === 0 && autoFocus}
          data-testid="input"
          autoComplete={isAutoFilled ? "one-time-code" : "off"}
        />,
      );
    }
    return inputs;
  }, [
    getOTPValue,
    OTPLength,
    inputClassName,
    activeInput,
    handleChange,
    handleKeyDown,
    handlePaste,
    handleFocus,
    disabled,
    autoFocus,
    hasError,
    inputType,
    isAutoFilled, // Include in dependencies to re-render inputs when this changes
  ]);

  return (
    <div className={`flex w-full items-center justify-center gap-2`} data-testid="otp-input-root">
      {renderInputs}
    </div>
  );
};

export default OTPInput;
