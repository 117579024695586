import { postRequest } from "lib/core/apiClient";
import Cookies from "js-cookie";
import { constants } from "lib/utils/constants";
import { OTPChannel, OTPVerificationDetails } from "lib/types/user";
import { jwtDecode } from "jwt-decode";

type AuthPayload = {
  context: "AUTH";
};

type SSOPayload = {
  context: "SSO";
  requestId: string;
};

export type OTPDeliveryType = AuthPayload | SSOPayload;

export const sendOtpWithPhoneNumber = async (
  details: OTPVerificationDetails,
  metada: OTPDeliveryType,
  reCaptchaToken: string = "",
) => {
  if (!details?.identifier || !details?.merchantId) return;

  try {
    const payload = {
      oid: details?.identifier,
      merchant_id: details?.merchantId,
      ...(metada.context === "AUTH" && {
        skip_verification: details?.skipVerification ?? false,
        linked_user_id: details.userId ?? null,
        is_force_verification: details.forceVerification ?? false,
      }),
      ...(metada.context === "SSO" && { context: "SSO", sso_request_id: metada.requestId }),
      ...(Boolean(reCaptchaToken) && { captcha_token: reCaptchaToken }),
      // domain: details.domain ?? "",
    };
    const response = await postRequest(`/otp/send`, payload, "AUTH");
    return response;
  } catch (e: any) {
    console.error(e);
    throw new Error(e);
  }
};

export const verifyOtp = async (contextId: string, otp: string, fingerprintData?: Record<string, any>, skipTokenGeneration?: boolean) => {
  if (!contextId || !otp) return;

  try {
    const payload = {
      context_id: contextId,
      otp: otp,
      skip_token_generation: skipTokenGeneration,
      ...(Boolean(fingerprintData) && Boolean(fingerprintData?.confidence?.score > 0.90) && { finger_print: fingerprintData?.visitorId }),
    };

    const response = await postRequest(`/otp/verify`, payload, "AUTH");
    return response;
  } catch (e: any) {
    console.error(e);
    throw new Error(e);
  }
};

export const resentOtp = async (contextId: string, channel: OTPChannel) => {
  if (!contextId) return;

  try {
    const payload = {
      context_id: contextId,
      preferred_channel: channel,
    };

    const response = await postRequest(`/otp/resend`, payload, "AUTH");
    return response;
  } catch (e: any) {
    console.error(e);
    throw new Error(e);
  }
};

export const refreshExpiredIdToken = async (isForceRefresh?: boolean, refreshTokenId?: string) => {
  const refreshToken = refreshTokenId ?? Cookies.get(constants.REFRESH_TOKEN_COOKIE_CLIENT);
  const authToken = Cookies.get(constants.AUTH_COOKIE_CLIENT);

  if (isForceRefresh || !authToken) {
    return false
  }

    try {
      const decodedToken = jwtDecode(authToken);
      const tokenExpiry = decodedToken.exp ? decodedToken.exp : 0
      const currentTime = Date.now() / 1000;
      const timeUntilExpiry = tokenExpiry - currentTime;
      if (timeUntilExpiry > 5 * 60) {
        return true;
      }
    } catch (e) {
      console.error("Error decoding token: ", e);
    }

  if (!Boolean(refreshToken)) return false;

  try {
    const fingerPrintData = JSON.parse(window.sessionStorage.getItem("@fpjs@client@__null__null__true") || "{}")?.body
    const payload = {
      refresh_token: refreshToken,
      ...(Boolean(fingerPrintData) && Boolean(fingerPrintData?.confidence?.score > 0.90) && { finger_print: fingerPrintData?.visitorId }),
    };

    const response = await postRequest(`/user-token/refresh`, payload, "AUTH");
    if (!response) return false;
    const newAuthToken = response.access_token;
    const newRefreshToken = response.refresh_token;
    const newAuthTokenExpiry = response.access_token_expires_at;
    const newRefreshTokenExpiry = response?.refresh_token_expires_at;
    setAuthCookies(newAuthToken, newRefreshToken, newAuthTokenExpiry, newRefreshTokenExpiry);
    return true;
  } catch (e: any) {
    Cookies.remove(constants.AUTH_COOKIE_CLIENT, { path: "/" });
    Cookies.remove(constants.REFRESH_TOKEN_COOKIE_CLIENT, { path: "/" });
    console.error(e);
    return false;
  }
};

export const loginWithFingerPrint = async (fingerPrintData: any) => {
  if (!fingerPrintData || !Boolean(fingerPrintData) || !Boolean(fingerPrintData?.confidence?.score > 0.90)) return;

  try {
    const payload = {
      finger_print: fingerPrintData?.visitorId,
    };

    const response = await postRequest(`/user-token/refresh`, payload, "AUTH");
    if (!response) return false;
    const newAuthToken = response.access_token;
    const newRefreshToken = response.refresh_token;
    const newAuthTokenExpiry = response.access_token_expires_at;
    const newRefreshTokenExpiry = response?.refresh_token_expires_at;
    setAuthCookies(newAuthToken, newRefreshToken, newAuthTokenExpiry, newRefreshTokenExpiry);
    return true;
  } catch (e: any) {
    Cookies.remove(constants.AUTH_COOKIE_CLIENT, { path: "/" });
    Cookies.remove(constants.REFRESH_TOKEN_COOKIE_CLIENT, { path: "/" });
    console.error(e);
    return false;
  }
};

export const setAuthCookies = (
  idToken: string,
  refreshToken: string,
  authExpiry?: number,
  refreshExpiry?: number,
) => {
  const authExpiresDate = authExpiry && new Date(authExpiry);
  const refreshExpiresDate = refreshExpiry && new Date(refreshExpiry);

  try {
    Cookies.set(constants.AUTH_COOKIE_CLIENT, idToken, {
      expires: authExpiresDate ?? constants.AUTH_COOKIE_CLIENT_EXPIRY,
      sameSite: "none",
      secure: true,
    });
    Cookies.remove(constants.AUTH_LOGGED_OUT);

    if (Boolean(refreshToken)) {
      Cookies.set(constants.REFRESH_TOKEN_COOKIE_CLIENT, refreshToken, {
        expires: refreshExpiresDate ?? constants.REFRESH_TOKEN_COOKIE_CLIENT_EXPIRY,
        sameSite: "none",
        secure: true,
      });
    } else {
      Cookies.set(constants.REFRESH_TOKEN_COOKIE_CLIENT, refreshToken, {
        expires: new Date(0),
        sameSite: "none",
        secure: true,
      });
      Cookies.remove(constants.REFRESH_TOKEN_COOKIE_CLIENT);
    }
  } catch (e: any) {
    console.error(e);
    throw new Error(e);
  }
};

export const expireFloAuthCookies = () => {
  Cookies.set(constants.AUTH_COOKIE_CLIENT, "", {
    expires: new Date(0),
    sameSite: "none",
    secure: true,
  });
  Cookies.set(constants.REFRESH_TOKEN_COOKIE_CLIENT, "", {
    expires: new Date(0),
    sameSite: "none",
    secure: true,
  });
};
