import React from "react";
import Price from "components/common/Price";
import { AppliedCouponType } from "lib/contexts/CheckoutProvider";
import { XCircle } from "react-feather";

interface AppliedItemProps {
  coupon: AppliedCouponType;
  handleDeleteItem: (value: string) => void;
}

const AppliedItem: React.FC<AppliedItemProps> = ({ coupon, handleDeleteItem }) => {
  return (
    <div className="flex w-full flex-col py-2.5 pr-4 pl-4">
      <div className="border-box flex w-full flex-row">
        <div className="flex w-full flex-row items-center justify-between overflow-hidden pr-2">
          <h3 className="flex w-full items-center space-x-1 truncate pr-2 text-sm font-normal text-coal-light">
            <p className="text-md truncate font-medium uppercase text-coal-dark">{`${
              Boolean(coupon?.title) ? coupon?.title : coupon?.code
            }`}</p>
          </h3>
          <p className="inline-flex w-1/2 justify-end overflow-hidden text-sm font-medium text-yay-dark">
            <Price total={coupon?.discountValue ?? ""} isDiscounted={true} />
          </p>
        </div>

        {/* Disable delete coupon button if it is auto applied */}
        {Boolean(coupon?.code) && Boolean(coupon?.isRemovable) && (
          <button
            className="flex h-4 w-4 items-center justify-center"
            onClick={() => handleDeleteItem(coupon?.code)}>
            <XCircle className="h-4 w-4 text-coal-dark" strokeWidth={2.5} />
          </button>
        )}
      </div>
    </div>
  );
};

export default AppliedItem;
