import { constants } from "lib/utils/constants";
import { loadScript } from "lib/utils/helpers";

const EASEBUZZ_PG_URL =
  "https://ebz-static.s3.ap-south-1.amazonaws.com/easecheckout/v2.0.0/easebuzz-checkout-v2.min.js";

export const loadEasebuzzDefaultSdk = async (key: string) => {
  const ENV = import.meta.env.VITE_NODE_ENV;

  try {
    const response = await loadScript(`${EASEBUZZ_PG_URL}`, async () => {
      await onEasebuzzDefaultSdkLoad(key);
    });
    return response;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

const onEasebuzzDefaultSdkLoad = (key: string) => {
  const ENV = import.meta.env.VITE_NODE_ENV;
  return new Promise((resolve, reject) => {
    try {
      (window as any).easebuzzDefaultSdk = new (window as any).EasebuzzCheckout(
        key,
        ENV === constants.ENV_PROD ? "prod" : "test",
      );
      resolve({});
    } catch (e) {
      throw reject(e);
    }
  });
};

interface EasebuzzDefaultPayment {
  accessKey: string;
  onSuccessHandler: () => void;
  onFailureHandler: () => void;
}
export const initializeEasebuzzDefaultPayment = async ({
  accessKey,
  onSuccessHandler,
  onFailureHandler,
}: EasebuzzDefaultPayment) => {
  const options = {
    access_key: accessKey,
    onResponse: (response: any) => {
      if (response.status === "success") {
        onSuccessHandler();
      }
      if (response.status === "failure") {
        onFailureHandler();
      }
    },
  };
  if ((window as any).easebuzzDefaultSdk) {
    (window as any).easebuzzDefaultSdk.initiatePayment(options);
  }
};
