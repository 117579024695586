import { inIframe, redirectUrl } from "lib/utils/helpers";
import { isMobile, detectDeviceOS } from "lib/utils/helpers";

export const invokeTruecallerDeeplink = (deeplink: string) => {
  if (!deeplink) return;
  if (!inIframe()) return;
  try {
    redirectUrl(deeplink);
  } catch (e) {
    console.error("Error while invoking truecaller", e);
  }
};

export const isTruecallerEnabled = () => {
  if (!isMobile()) return false;
  if (detectDeviceOS() === "Android") return true;
  return false;
};
