import React, { useCallback, useEffect, useState } from "react";
import { AppliedCouponType } from "lib/contexts/CheckoutProvider";
import { useLocale } from "lib/hooks/useLocale";
import { Tag } from "react-feather";
import ConfirmationPopup from "components/common/popups/ConfirmationPopup";
import CouponsIcon from "assests/images/coupons.svg";
import { getMaxCouponDiscount, findIdCouponByCode, getItems } from "lib/utils/checkout";
import { useAuthContext } from "lib/contexts/AuthProvider";
import useSendAnalyticsEvent from "lib/hooks/useAnalytics";
import { analyticsEvents } from "lib/utils/constants";
import Price from "components/common/Price";
import { getRequest, postRequest, putRequest } from "lib/core/apiClient";
import { errorToast } from "lib/utils/toasters";
import { mutate } from "swr";
import OverlaySpinner from "components/common/loaders/OverlaySpinner";
import { ShippingHandlesType } from "lib/types/checkout";
import { useCheckoutContext } from "lib/contexts/CheckoutProvider";

interface CheckoutExitPopupProps {
  isOpen: boolean;
  closeCheckout: () => void;
  closePopup: () => void;
}

let bannerType: "coupons_applied" | "coupons_available" | "generic" = "generic";
let totalAfterMaxDiscount: number = 0;

const CheckoutExitPopup: React.FC<CheckoutExitPopupProps> = React.memo(
  ({ isOpen = false, closeCheckout, closePopup }) => {
    const {
      state: { appliedCoupons, checkoutId, coupons, billing },
      actions: {
        updateCheckoutBasedOnCheckoutResponse,
        setCoupons,
        setCheckoutItems,
        setShippingHandles,
        setCheckoutModal,
      },
    } = useCheckoutContext();
    const { t } = useLocale();
    const {
      state: { isAuthenticated },
    } = useAuthContext();
    const { sendAnalyticsEvent } = useSendAnalyticsEvent();

    const [popupTitle, setPopupTitle] = useState<string | JSX.Element>("");
    const [popupBody, setPopupBody] = useState<string | JSX.Element>("");
    const [primaryBtnText, setPrimaryBtnText] = useState<string>(t("stay"));
    const [cancelBtnText, setCancelBtnText] = useState<string>(t("exit_anyway"));
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const { maxCouponDiscountAmount, maxCouponDiscountCode } = getMaxCouponDiscount(coupons);

    useEffect(() => {
      onInit();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appliedCoupons, coupons, billing, closePopup]);

    const onInit = () => {
      const totalDiscount = billing?.discount;
      let body: JSX.Element = <></>;
      const filteredAppliedCoupons = appliedCoupons?.filter(
        (coupon: AppliedCouponType) => !Boolean(coupon.isPrepaid),
      );
      //Case where totalDiscount is more than 0. Hence, missing out on savings popup.
      if (Boolean(totalDiscount)) {
        const title: JSX.Element = (
          <h1 className="flex gap-1 text-base font-medium text-carbon-dark">
            {t("cancel_checkout_header_with_savings")}{" "}
            <span className="text-yay-dark">
              <Price total={totalDiscount ?? ""} />
            </span>
          </h1>
        );
        setPopupTitle(title);
        //If any coupons/freebies are applied in the checkout, based on the count show the popup body
        if (Boolean(filteredAppliedCoupons)) {
          if (Boolean(filteredAppliedCoupons?.length > 1)) {
            body = (
              <>
                <div className="flex flex-row items-start gap-2 rounded-lg bg-gradient-to-r from-yay-light px-2 py-3">
                  <div>
                    <img src={CouponsIcon} alt="flo-coupons-savings" />
                  </div>

                  <p className="break-words text-sm font-normal text-coal-light">
                    <span className="text-sm font-semibold text-coal-dark">
                      {filteredAppliedCoupons?.length}
                      {" discount coupons "}
                    </span>
                    {t("cancel_checkout_body_with_savings", { count: filteredAppliedCoupons?.length })}
                  </p>
                </div>
              </>
            );
          } else {
            body = (
              <>
                <div className="flex flex-row items-start gap-2 rounded-lg bg-gray-lighter px-2 py-3">
                  <div>
                    <Tag className="h-4 w-4 text-coal-dark" strokeWidth={2} />
                  </div>

                  <p className="break-words text-sm font-normal text-coal-light">
                    <span className="text-sm font-medium uppercase text-coal-dark">
                      {filteredAppliedCoupons[0]?.title
                        ? filteredAppliedCoupons[0]?.title
                        : filteredAppliedCoupons[0]?.code}
                      &nbsp;
                    </span>
                    {t("cancel_checkout_body_with_savings", { count: filteredAppliedCoupons?.length })}
                  </p>
                </div>
              </>
            );
          }
          setPopupBody(body);
          bannerType = "coupons_applied";
        } else {
          setPopupBody(t("cancel_checkout"));
        }
        setPrimaryBtnText(t("stay"));
      } else {
        //If not discount is applied, show the possible applicable discounts.
        const subTotal: number = billing.sub_total;
        totalAfterMaxDiscount = subTotal - maxCouponDiscountAmount;
        if (totalAfterMaxDiscount < subTotal) {
          const title: JSX.Element = (
            <h1 className="flex gap-1 text-base font-medium text-carbon-dark">
              {t("cancel_checkout_header_possible_savings")}{" "}
              <span className="text-[#61B643]">
                <Price total={totalAfterMaxDiscount ?? ""} />
              </span>
            </h1>
          );
          body = (
            <>
              <div className="flex flex-row items-start gap-2 rounded-lg bg-gray-lighter px-2 py-3">
                <div>
                  <Tag className="h-4 w-4 text-coal-dark" strokeWidth={2} />
                </div>

                <p className="break-words text-sm font-normal text-coal-light">
                  <span className="text-sm font-medium uppercase text-coal-dark">
                    {maxCouponDiscountCode}&nbsp;
                  </span>
                  {t("cancel_checkout_body_possible_savings")}
                </p>
              </div>
            </>
          );
          setPopupTitle(title);
          setPopupBody(body);
          setPrimaryBtnText(t("apply_coupons"));
          bannerType = "coupons_available";
          return;
        }
        //If there are no applicable discounts, throw generic message
        setPopupTitle(t("cancel_checkout_header"));
        setPopupBody(t("cancel_checkout"));
        setPrimaryBtnText(t("stay"));
        bannerType = "generic";
      }
    };

    const sendBannerAnalyticsEvent = useCallback(
      (actionType: "APPLY_COUPON" | "STAY" | "EXIT_CHECKOUT" | "CLOSE_BANNER") => {
        let bannerData = {};
        switch (bannerType) {
          case "coupons_applied": {
            bannerData = {
              banner_type: "coupons_applied",
              coupons: appliedCoupons,
              cart_value: billing?.total_payable,
              savings: billing?.discount,
            };
            break;
          }
          case "coupons_available": {
            bannerData = {
              banner_type: "coupons_available",
              coupons: coupons,
              cart_value: billing?.total_payable,
              savings: billing?.sub_total - totalAfterMaxDiscount,
            };
            break;
          }
          default: {
            bannerData = {
              banner_type: "generic",
              coupons: [],
              cart_value: billing?.total_payable,
              savings: 0,
            };
          }
        }
        sendAnalyticsEvent({
          eventName: analyticsEvents.FLO_EXIT_BANNER_ACTION,
          eventType: "flo_action",
          metaData: {
            bannerData: { ...bannerData, actionType },
          },
        });
      },
      [appliedCoupons, billing, coupons],
    );

    const handleDiscountApply = async () => {
      if (!Boolean(maxCouponDiscountCode?.trim())) return;

      const couponId = findIdCouponByCode(coupons, maxCouponDiscountCode);
      //Coupon entered/selected event
      sendAnalyticsEvent({
        eventName: analyticsEvents.FLO_COUPON_SELECTED,
        eventType: "click",
        metaData: {
          couponData: {
            coupon_code: maxCouponDiscountCode,
            coupon_id: Boolean(couponId) ? couponId : "NA",
          },
        },
      });

      const payload = {
        discount_code: maxCouponDiscountCode?.trim(),
      };

      try {
        setIsLoading(true);
        let response;
        if (isAuthenticated) {
          response = await postRequest(`/checkout/v1/checkout/${checkoutId}/discounts`, payload);
          const discountResponse = await getRequest(`/checkout/v1/checkout/${checkoutId}/discounts`);
          setCoupons(discountResponse);
        } else {
          response = await putRequest(`/v1/checkout/${checkoutId}/discounts`, payload, "CHECKOUT_PUBLIC");
          const discountResponse = await getRequest(
            `/v1/checkout/${checkoutId}/discounts`,
            "CHECKOUT_PUBLIC",
          );
          setCoupons(discountResponse);
        }
        if (!response) {
          errorToast(t("coupon_not_found"));
          return;
        }

        updateCheckoutBasedOnCheckoutResponse(response);

        setIsLoading(false);

        if (Boolean(response?.items)) {
          setCheckoutItems(getItems(response?.items));
        }

        //coupon success event
        sendAnalyticsEvent({
          eventName: analyticsEvents.FLO_COUPON_SUCCESS,
          eventType: "flo_action",
          metaData: {
            couponData: {
              coupon_code: maxCouponDiscountCode,
              coupon_id: Boolean(couponId) ? couponId : "NA",
            },
          },
        });

        const isAddressServiceable = response?.pricing?.serviceable ?? false;
        if (!Boolean(isAddressServiceable)) {
          errorToast(t("serviceability_error"), 5000);
          return;
        }

        const shippingHandles = response?.metadata?.available_shipping_handles;
        const showShippingHandles = response?.metadata?.show_shipping_handle_selector ?? false;
        setShippingHandles(shippingHandles as ShippingHandlesType);

        if (Boolean(showShippingHandles)) {
          setCheckoutModal("SHIPPING_HANDLES");
        } else {
          mutate(`/checkout/v2/checkout/${checkoutId}/payments`);
          mutate(`UPI_INTENT`);
        }
      } catch (e: any) {
        setIsLoading(false);
        const errorMsg = e?.response?.data?.error;
        errorToast(errorMsg ?? t("coupon_not_found"));
        //coupon failed event
        sendAnalyticsEvent({
          eventName: analyticsEvents.FLO_COUPON_FAILED,
          eventType: "flo_action",
          metaData: {
            couponData: {
              coupon_code: maxCouponDiscountCode,
              coupon_id: Boolean(couponId) ? couponId : "NA",
              failed_reason: errorMsg,
            },
          },
        });
        console.error(e);
      }
    };

    return (
      <>
        <ConfirmationPopup
          popupTitle={popupTitle}
          popupBody={popupBody}
          primaryBtnText={primaryBtnText}
          cancelBtnText={cancelBtnText}
          onClickPrimaryBtn={() => {
            closePopup();
            primaryBtnText === t("apply_coupons") && handleDiscountApply();
            sendBannerAnalyticsEvent(primaryBtnText === t("apply_coupons") ? "APPLY_COUPON" : "STAY");
          }}
          onClickSecondaryBtn={() => {
            closeCheckout();
            sendBannerAnalyticsEvent("EXIT_CHECKOUT");
          }}
          isOpen={isOpen}
          closePopup={() => {
            closePopup();
            sendBannerAnalyticsEvent("CLOSE_BANNER");
          }}
          modalType="EXIT_CONFIRMATION"
        />
        {isLoading && <OverlaySpinner />}
      </>
    );
  },
);

export default CheckoutExitPopup;
