export type UpSellVariantType = {
  variantId: string;
  name: string;
  inventoryQuantity: number;
  inventoryPolicy: "CONTINUE" | "DENY";
  currentPrice: number;
  originalPrice: number;
  outOfStock: boolean;
  imageLink: string;
  currency?: string;
  taxable?: boolean;
  requiresShipping?: boolean;
  createdAt?: number;
  updatedAt?: number;
  isDiscountedUpsell?: boolean;
  discountedPrice?: number;
};

export type UpSellProductType = {
  productId: string;
  name: string;
  variants: UpSellVariantType[];
  allVariantOutOfStock: boolean;
  upsellIds: Array<string>;
  createdAt?: number;
  updatedAt?: number;
};

export enum UpsellGroupsOnDiscountType {
  DISCOUNTED = "DISCOUNTED",
  NON_DISCOUNTED = "NON_DISCOUNTED",
}

export type UpsellPositionType = "CART" | "PAYMENT_PAGE";
