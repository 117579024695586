import React from "react";
import floLogoMono from "assests/images/flo-logo-mono.svg";

interface FloRippleProps {}

const FloRipple: React.FC<FloRippleProps> = ({}) => {
  return (
    <>
      <div className="flex h-full w-full flex-col items-center justify-center">
        <span className="flo-loader__rippleSpan">
          <img src={floLogoMono} alt="flo-logo-mono" />
        </span>
      </div>
    </>
  );
};

export default FloRipple;
