// @ts-nocheck
export const addPolyfills = () => {
  if (!Promise.allSettled) {
    allSettledPolyfill();
  }
  stringReplaceAll();
};

const allSettledPolyfill = () => {
  const rejectHandler = (reason: any) => ({ status: "rejected", reason });

  const resolveHandler = (value: any) => ({ status: "fulfilled", value });

  Promise.allSettled = function (promises: any) {
    const convertedPromises = promises.map((p: any) =>
      Promise.resolve(p).then(resolveHandler, rejectHandler),
    );
    return Promise.all(convertedPromises);
  };
};

const stringReplaceAll = () => {
  if (!String.prototype.replaceAll) {
    try {
      String.prototype.replaceAll = function (str: string, newStr: string) {
        if (Object.prototype.toString.call(str).toLowerCase() === "[object regexp]") {
          return this.replace(str, newStr);
        }
        return this.replace(new RegExp(str, "g"), newStr);
      };
    } catch (e) {
      console.error("polyfill not added", e);
    }
  }
};
