import * as Sentry from "@sentry/react";
import { useEffect, useState } from "react";

const useSentry = () => {
  const [isSentryLoaded, setIsSentryLoaded] = useState(false);
  const dns = import.meta.env.VITE_SENTRY_DNS;
  useEffect(() => {
    if (dns && !isSentryLoaded) {
      Sentry.init({
        dsn: dns,
        // release: sentryConfig.release, //Todo: add a release version
        environment: import.meta.env.VITE_NODE_ENV,
        integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
        tracesSampler(samplingContext) {
          if (samplingContext.parentSampled !== undefined) {
            return samplingContext.parentSampled;
          }

          return 0.1;
        },
        replaysOnErrorSampleRate: 1.0,
      });
      setIsSentryLoaded(true);
    }
    // if (user) {
    //   Sentry.setUser({
    //     id: user.userUuid,
    //     email: user.email,
    //     username: user.email,
    //   });
    //   Sentry.setTag("organization", user.organizationUuid);
    // }
  }, [isSentryLoaded, setIsSentryLoaded]);
};

export default useSentry;
