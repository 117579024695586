import React, { createContext, useState } from "react";

interface LoaderContextType {
  isLoading: boolean;
  setIsLoading: (value: boolean) => void;
}

const LoaderContext = createContext({} as LoaderContextType);

const LoaderProvider = (props: any) => {
  const [isLoading, setIsLoadingCtx] = useState<boolean>(false);

  const setIsLoading = (value: boolean) => {
    setIsLoadingCtx(value);
  };
  const loaderContextValues: LoaderContextType = {
    isLoading,
    setIsLoading,
  };

  return <LoaderContext.Provider value={loaderContextValues} {...props} />;
};

const useLoader = () => React.useContext(LoaderContext);

export { LoaderProvider, useLoader };
