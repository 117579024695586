import { eventTypes } from "lib/utils/constants";
import { publishPostMessage } from "lib/utils/helpers";

interface LinkButtonProps {
  children: JSX.Element;
  href: string;
}

export default function LinkButton({ children, href, ...props }: LinkButtonProps): JSX.Element {
  if (!Boolean(href)) {
    return children;
  }

  const handleRedirection = () => {
    publishPostMessage(eventTypes.PARENT_REDIRECT, { redirectUrl: href });
  };

  return (
    <button onClick={handleRedirection} className="text-left" {...props}>
      {children}
    </button>
  );
}
