import { AddressCardFields, UserAddress } from "lib/types/address";
import { arrayMove } from "lib/utils/helpers";
import { capitalizeFirstCharacter } from "lib/utils/helpers";
import { FormField, ValidationSchema } from "lib/hooks/useForm";
import { t } from "i18next";
import { isNumeric } from "lib/utils/validations";
import { formatZip } from "lib/utils/formatters";
import { ValidationItem } from "lib/types/validation";

/**
 * Takes address ID as an argument and returns the address object from the list of addresses from attributes data
 *
 * @param  {string} addressId           Address ID
 *
 * @return {object} AddressCardFields   Parsed address based on the passed address ID
 */
export const getAddressById = (addressId: string, addresses: any) => {
  let address: AddressCardFields = {};
  for (let i = 0; i < addresses?.length; i++) {
    if (addressId === addresses[i]?.uid) {
      address = {
        type: addresses[i]?.address_type?.trim() || "Other",
        id: addresses[i]?.uid,
        name: addresses[i]?.data?.name,
        phone: addresses[i]?.data?.phone,
        email: addresses[i]?.data?.email,
        address1: addresses[i]?.data?.address1 ?? "",
        address2: addresses[i]?.data?.address2 ?? "",
        address: concateAddresses(
          addresses[i]?.data?.address1,
          addresses[i]?.data?.address2,
          capitalizeFirstCharacter(addresses[i]?.data?.city),
          capitalizeFirstCharacter(addresses[i]?.data?.state),
          capitalizeFirstCharacter(addresses[i]?.data?.country),
          addresses[i]?.data?.zip,
        ),
        zip: addresses[i]?.data?.zip ?? "",
        city: addresses[i]?.data?.city ?? "",
        state: addresses[i]?.data?.state ?? "",
        country: addresses[i]?.data?.country ?? "",
        source: addresses[i]?.source_type,
      };
      break;
    }
  }
  return address;
};

/**
 * Takes address ID as an argument and returns the address object from the list of addresses from attributes data
 *
 * @param  {string} addressId           Address ID
 *
 * @return {object} AddressCardFields   Parsed address based on the passed address ID
 */
export const getUserAddressById = (addressId: string, addresses: any) => {
  let address: UserAddress = {};
  for (let i = 0; i < addresses?.length; i++) {
    if (addressId === addresses[i]?.id) {
      address = {
        type: addresses[i]?.address_type?.trim() || "Others",
        id: addresses[i]?.id,
        name: addresses[i]?.name,
        phone: addresses[i]?.phone,
        email: addresses[i]?.email,
        city: addresses[i]?.city,
        state: addresses[i]?.state,
        country: addresses[i]?.country,
        zip: addresses[i]?.zip,
        address1: addresses[i]?.address1 ?? "",
        address2: addresses[i]?.address2 ?? "",
        address: concateAddresses(
          addresses[i]?.address1,
          addresses[i]?.address2,
          capitalizeFirstCharacter(addresses[i]?.city),
          capitalizeFirstCharacter(addresses[i]?.state),
          capitalizeFirstCharacter(addresses[i]?.country),
          addresses[i]?.zip,
        ),
        state_code: addresses[i]?.state_code,
        country_code: addresses[i]?.country_code,
        gst_business_name: addresses[i]?.gst_business_name,
        gstin: addresses[i]?.gstin,
        alternate_number: addresses[i]?.alternate_number,
      };
      break;
    }
  }
  return address;
};

export const formatAllAddresses = (addresses: any, defaultAddressId: string) => {
  if (!Boolean(addresses?.length)) return;
  let allAddresses: AddressCardFields[] = [];
  let defaultAddressIndex: number = -1;

  //Parse addresses based on required fields
  addresses.forEach((address: any) => {
    const curateAddress: AddressCardFields = {
      ...(Boolean(address.uid) && { id: address.uid }),
      ...(Boolean(address.data?.name) && { name: address.data?.name }),
      ...(Boolean(address.data?.firstname) && { firstname: address.data?.firstname }),
      ...(Boolean(address.data?.lastname) && { lastname: address.data?.lastname }),
      ...(Boolean(address.data?.phone) && { phone: address.data?.phone }),
      ...(Boolean(address.data?.email) && { email: address.data?.email }),
      ...(Boolean(address.data?.address1) && { address1: address.data?.address1 }),
      ...(Boolean(address.data?.address2) && { address2: address.data?.address2 }),
      ...(Boolean(address.data?.zip) && { zip: address.data?.zip }),
      ...(Boolean(address.data?.city) && { city: address.data?.city }),
      ...(Boolean(address.data?.state) && { state: address.data?.state }),
      ...(Boolean(address.data?.gstin) && { gstin: address.data?.gstin }),
      ...(Boolean(address.data?.gst_business_name) && { gst_business_name: address.data?.gst_business_name }),
      ...(Boolean(address.source_type) && { source: address.source_type }),
      // ...(Boolean(address.data?.state_code) && { state_code: address.data?.state_code }),
      state_code: address.data?.state_code ?? "",
      ...(Boolean(address.data?.country) && { country: address.data?.country }),
      ...(Boolean(address.data?.country_code) && { country_code: address.data?.country_code }),
      ...(Boolean(address.address_type) && { type: address.address_type?.trim() || "Other" }),
      ...(Boolean(address.data?.alternate_number) && { alternate_number: address.data?.alternate_number }),
      address: concateAddresses(
        address.data?.address1,
        address.data?.address2,
        capitalizeFirstCharacter(address.data?.city),
        capitalizeFirstCharacter(address.data?.state),
        capitalizeFirstCharacter(address.data?.country),
        address.data?.zip,
      ),
    };
    allAddresses.push(curateAddress);
  });

  //Sort addresses by address type in alphabetical order
  allAddresses.sort((a: any, b: any) => (a?.type > b?.type ? 1 : -1));
  allAddresses.forEach((address: any, index: number) => {
    if (defaultAddressId === address.id) defaultAddressIndex = index;
  });

  //move the default address to first position
  if (defaultAddressIndex > 0) arrayMove(allAddresses, defaultAddressIndex, 0);
  return allAddresses;
};

export const concateAddresses = (
  address1: string,
  address2: string,
  city: string,
  state: string,
  country: string,
  zip: string,
) => {
  let result = "";
  if (address1) result += address1 + ", ";
  if (address2) result += address2 + ", ";
  if (city) result += capitalizeFirstCharacter(city) + ", ";
  if (state) result += capitalizeFirstCharacter(state) + ", ";
  if (country) result += capitalizeFirstCharacter(country) + ", ";
  if (zip) result += zip;
  return result;
};

/**
 * Takes addresses and address type as arguments and returns if the particular address type(HOME/WORK) is present in the list of addresses
 *
 * @param  {Array[{}]} addresses           Addresses array of object
 *
 * @return {boolean} boolean   Returns true if the address type is present in the list of addresses
 */
export const hasAddressType = (addresses: any, addressType: string) => {
  for (let i = 0; i < addresses?.length; i++) {
    if (addresses[i].address_type === addressType) return true;
  }
  return false;
};

/**
 * Method takes field key and isMandatory, returns the properties of that field
 *
 * @param  {string} key               ID of the field
 * @param  {boolean} isMandatory      If the field is required or optional
 *
 * @return {object}                   Returns the field properties
 */
export const getFieldProperties = (
  key: string,
  isMandatory: boolean,
  selectedFieldData?: any,
  alternateNumberEnabled?: boolean,
): ValidationItem => {
  let property = {};
  switch (key) {
    case "phone": {
      property = {
        type: "string",
        required: t("invalid_phone_number"),
        phone: t("invalid_phone_number"),
        formatters: ["PHONE"],
      };
      break;
    }
    case "zip": {
      property = {
        ...(Boolean(isMandatory) && {
          required: t("pincode_required"),
        }),
        type: "string",
        when: (value: string, values: any) => {
          if (values.country_code === "IN" && (value.length !== 6 || !isNumeric(value))) {
            return {
              status: true,
              message: t("valid_pincode"),
            };
          }
        },
        formatters: [formatZip],
      };
      break;
    }
    case "name": {
      property = {
        type: "string",
        required: t("name_required"),
        min: {
          limit: 3,
          message: t("valid_name"),
        },
        max: {
          limit: 450,
          message: t("valid_name"),
        },
        onlyLettersAndPeriod: t("valid_name"),
        formatters: ["LETTERS_AND_PERIOD"],
      };
      break;
    }
    case "city": {
      property = {
        type: "string",
        ...(Boolean(isMandatory) && {
          required: t("city_required"),
        }),
        formatters: ["CAPITALIZE", "LETTERS_ONLY"],
        onlyLetters: t("valid_city"),
      };
      break;
    }
    case "state": {
      property = {
        type: "string",
        ...(Boolean(isMandatory) && {
          required: t("state_required"),
        }),
        formatters: ["CAPITALIZE", "LETTERS_ONLY"],
      };
      break;
    }
    case "state_code": {
      property = {
        type: "string",
        ...(Boolean(isMandatory) && {
          required: t("state_required"),
        }),
        notSpecialCharactersOrNumbersOnly: t("valid_state"),
        formatters: ["CAPITALIZE", "LETTERS_ONLY"],
      };
      break;
    }
    case "country": {
      property = {
        ...(Boolean(isMandatory) && {
          required: t("valid_country"),
        }),
        type: "string",
        onlyLetters: t("valid_country"),
        formatters: ["CAPITALIZE"],
      };
      break;
    }
    case "country_code": {
      property = {
        ...(Boolean(isMandatory) && {
          required: t("country_code_required"),
        }),
        type: "string",
      };
      break;
    }
    case "type": {
      property = {
        required: t("address_type_required"),
      };
      break;
    }
    case "email": {
      property = {
        type: "string",
        ...(Boolean(isMandatory)
          ? {
              required: t("email_required"),
              email: t("valid_email"),
            }
          : {
              emailOrEmptyString: t("valid_email"),
            }),
        formatters: ["FIRST_CHAR_LOWERCASE", "LETTERS_WITH_NO_SPACE"],
      };
      break;
    }
    case "gstin": {
      property = {
        type: "string",
        formatters: ["UPPERCASE", "ALPHA_NUMERIC"],
        // min: {
        //   limit: 15,
        //   message: t("invalid_gstin_error"),
        // },
      };
      break;
    }
    case "gst_business_name": {
      property = {
        type: "string",
        ...(Boolean(isMandatory) && {
          required: t("gst_business_name_required"),
        }),
      };
      break;
    }
    case "address1": {
      property = {
        type: "string",
        label: selectedFieldData?.label ?? t("address1"),
        ...(Boolean(isMandatory) && {
          required: t("full_address"),
          notSpecialCharactersOrNumbersOnly: t("valid_address"),
          min: {
            limit: 12,
            message: t("full_address"),
          },
        }),
      };
      break;
    }
    case "address2": {
      property = {
        type: "string",
        label: selectedFieldData?.label ?? t("address2"),
        ...(Boolean(isMandatory) && {
          required: t("full_address"),
          notSpecialCharactersOrNumbersOnly: t("valid_address"),
          min: {
            limit: 12,
            message: t("full_address"),
          },
        }),
      };
      break;
    }
    case "alternate_number": {
      property = {
        type: "string",
        label: selectedFieldData?.label ?? t("local_delivery_phone_number"),
        ...(Boolean(alternateNumberEnabled) && {
          required: t("invalid_phone_number"),
          phone: t("invalid_phone_number"),
          formatters: ["PHONE"],
        }),
      };
      break;
    }
  }

  return property;
};

/**
 * Takes address configuration and constructs address object with their respective properties
 *
 * @param  {Array[{}]} addressConfig         Configuration of various address fields
 *
 * @return {ValidationSchema}               Returns Validation Schema of the addresses
 */
export const getAddressFields = (
  addressConfig: string[],
  states: any,
  editAddressFields?: string[],
  alternateNumberEnabled?: boolean,
) => {
  let validationSchema = {} as ValidationSchema;
  if (!Boolean(addressConfig)) return validationSchema;
  //TODO: Logic to be changed to BE for gst fields.
  addressConfig.forEach((field: any) => {
    if (field.isEnabled && field.key !== "gstin") {
      validationSchema[field.key as FormField] = getFieldProperties(
        field.key,
        field.isMandatory,
        field,
        alternateNumberEnabled,
      );
    } else if (
      field.key !== "gstin" &&
      field.key !== "alternate_number" &&
      editAddressFields?.includes(field.key)
    ) {
      validationSchema[field.key as FormField] = getFieldProperties(
        field.key,
        false,
        field,
        alternateNumberEnabled,
      );
    }

    if (field.key === "state_code" && !Boolean(states?.length)) {
      delete validationSchema[field.key as FormField];
    }
  });
  return validationSchema;
};

/**
 * Takes address configuration and constructs address object with their respective properties
 *
 * @param  {Array[{}]} addressConfig         Configuration of various address fields
 *
 * @return {ValidationSchema}               Returns Validation Schema of the addresses
 */
export const getBillingAddressFields = (addressConfig: string[], isGSTBusineeNameMandatory: boolean) => {
  const scheme: any = addressConfig.filter(
    (conf: any) =>
      conf?.key === "address1" ||
      conf?.key === "address2" ||
      conf?.key === "zip" ||
      conf?.key === "city" ||
      conf?.key === "state",
  );

  let validationSchema = {} as ValidationSchema;
  scheme.forEach((field: any) => {
    if (field.isEnabled) {
      validationSchema[field.key as FormField] = getFieldProperties(field.key, field.isMandatory);
    }
  });

  validationSchema = {
    ...validationSchema,
    gstin: getFieldProperties("gstin", false),
    gst_business_name: getFieldProperties("gst_business_name", isGSTBusineeNameMandatory ?? false),
  };
  return validationSchema;
};
