import React from "react";
import { DialogHeader, DialogBody } from "components/common/dialogs/GenericDialog";
import { XIcon } from "@heroicons/react/solid";
import { useLocale } from "lib/hooks/useLocale";
import PrimaryButton from "components/common/buttons/PrimaryButton";
import { useCheckoutContext } from "lib/contexts/CheckoutProvider";
import GenericButton from "components/common/buttons/GenericButton";
import { CouponReplacementInvalidityReasonType } from "lib/types/checkout";
import { Trans } from "react-i18next";

export interface SwitchCouponDialogProps {
  closePopup: () => void;
  applyCoupon: () => void;
  appliedDiscountCode: string;
  invalidDiscountCodes: string[];
  invalidReason: CouponReplacementInvalidityReasonType;
  isReward?: boolean;
}

const SwitchCouponDialog: React.FC<SwitchCouponDialogProps> = ({
  closePopup,
  applyCoupon,
  appliedDiscountCode,
  invalidDiscountCodes,
  invalidReason,
  isReward = false,
}) => {
  const { t } = useLocale();
  const {
    state: { appliedCoupons, appliedWalletData },
  } = useCheckoutContext();

  const getInvalidItemsText = () => {
    if (invalidDiscountCodes?.length === 1) {
      return invalidDiscountCodes[0];
    }
    if (invalidDiscountCodes?.length === 2) {
      return `${invalidDiscountCodes[0]} and ${invalidDiscountCodes[1]}`;
    }
    if (invalidDiscountCodes?.length > 2) {
      return `${invalidDiscountCodes.slice(0, -1).join(",")} and ${
        invalidDiscountCodes[invalidDiscountCodes.length - 1]
      }`;
    }
  };

  const headerText = isReward
    ? t("replace_reward_header")
    : t("replace_coupon_header", { count: appliedCoupons?.length });

  const bodyText = isReward
    ? t("cannot_combine_rewards", {
        invalid_items: getInvalidItemsText(),
        applied_item: appliedDiscountCode,
      })
    : invalidReason === "DISCOUNTS_NOT_COMBINABLE"
    ? t("cannot_combine_coupons", {
        invalid_coupons: getInvalidItemsText(),
        applied_coupon: appliedDiscountCode,
      })
    : t("will_be_replaced_by", {
        invalid_coupons: getInvalidItemsText(),
        applied_coupon: appliedDiscountCode,
      });

  return (
    <>
      <DialogHeader>
        <div className="flex h-full w-full flex-row items-center justify-between">
          <h1 className={`text-base font-medium ${isReward ? "pt-4" : ""}`}>{headerText}</h1>
          <button className="outline-none">
            <XIcon className="h-6 w-6 cursor-pointer text-coal-dark" onClick={() => closePopup()} />
          </button>
        </div>
      </DialogHeader>

      <DialogBody className="!pb-4 !pt-12">
        <div className="relative flex w-full flex-col space-y-4 px-6 pb-8 pt-4">
          <p className="text-sm text-coal-dark">
            <Trans i18nKey={headerText}>
              <strong>{getInvalidItemsText()}</strong> cannot be combined with{" "}
              <strong>{appliedDiscountCode}</strong>
            </Trans>
          </p>
          <PrimaryButton
            buttonText={t("Yes, replace")}
            onClick={applyCoupon}
            onTouchStart={applyCoupon}
            height="h-14"
            isLoading={false}
            isDisabled={false}
          />
          <GenericButton
            buttonText={isReward ? t("keep_existing_rewards") : t("keep_existing_discounts")}
            height="h-14"
            customClass="border-primary-dark border rounded-[12px] text-primary-dark font-semibold"
            onClick={closePopup}
            onTouchStart={closePopup}
          />
        </div>
      </DialogBody>
    </>
  );
};

export default SwitchCouponDialog;
