import * as React from "react";
import { isPossiblePhoneNumber, isValidPhoneNumber } from "react-phone-number-input";
import { ErrorType } from "lib/types/address";
import {
  isValidEmail,
  isIndianNumber,
  containsEmojis,
  isNumeric,
  regexPatterns,
} from "lib/utils/validations";
import { FormatterTypes, LengthLimitType, ValidationItem } from "lib/types/validation";
import { areObjectsEqual, capitalizeFirstCharacter } from "lib/utils/helpers";
import { useLocale } from "lib/hooks/useLocale";
import { usePrevious } from "lib/hooks/usePrevious";

export type ValidationSchema = Record<FormField, ValidationItem>;

export enum FormField {
  // common login validation
  phoneNumber = "phoneNumber",
  otp = "otp",
  // address validation
  phone = "phone",
  alternate_number = "alternate_number",
  id = "id",
  email = "email",
  name = "name",
  address1 = "address1",
  address2 = "address2",
  zip = "zip",
  city = "city",
  state = "state",
  state_code = "state_code",
  country = "country",
  country_code = "country_code",
  type = "type",
  // business validation
  gstin = "gstin",
  gst_business_name = "gst_business_name",
  // Card validation
  cardName = "cardName",
  cardNumber = "cardNumber",
  expiry = "expiry",
  cvv = "cvv",
}

interface UseFormProps<T> {
  initialState: T;
  validationSchema: Partial<ValidationSchema>;
  skipValidation?: boolean;
  initialErrorState?: Record<string, ErrorType | undefined>;
}

export default function useForm<T extends Record<string, unknown>>({
  initialState,
  validationSchema,
  skipValidation = false,
  initialErrorState,
}: UseFormProps<T>) {
  const [values, setValues] = React.useState(initialState);
  const [errors, setErrors] = React.useState<Record<string, ErrorType | undefined>>(
    initialErrorState ? initialErrorState : {},
  );
  const [touched, setTouched] = React.useState<Record<string, boolean>>({});
  const [validations, setValidations] = React.useState<Record<string, boolean>>({});
  const [fieldsValidated, setFieldsValidated] = React.useState<boolean>(false);
  const [shouldScroll, setShouldScroll] = React.useState<boolean>(true);

  const { t } = useLocale();

  React.useEffect(() => {
    if (!fieldsValidated && !areObjectsEqual(values, initialState)) {
      setFieldsValidated(true);
      validateAutoFilledFields();
    }
  }, [values]);

  React.useEffect(() => {
    validateAutoFilledFields();
  }, []);

  const validateAutoFilledFields = () => {
    Object?.keys(values).map((key) => {
      if (values[key]) {
        setValidations((vlds) => ({ ...vlds, [key]: true }));
      }
    });
  };

  const setValueOf = (field: string, value: unknown) => {
    setValues((values: any) => ({ ...values, [field]: value }));
  };

  const format = (value: any = "", formatters: FormatterTypes[]) => {
    const formattedValue: string = formatters.reduce((formatted: string, formatter) => {
      switch (formatter) {
        case "UPPERCASE": {
          return formatted.toUpperCase();
        }
        case "FIRST_CHAR_LOWERCASE": {
          return formatted.charAt(0).toLowerCase() + formatted.slice(1);
        }
        case "CAPITALIZE": {
          return capitalizeFirstCharacter(formatted);
        }
        case "PHONE": {
          if (isIndianNumber(formatted) && formatted[3] === "0") {
            return formatted.slice(0, 3) + formatted.slice(4);
          }
          return formatted;
        }
        case "CLEAN_EMOJIS": {
          return formatted.replace(regexPatterns.hasEmoji, "");
        }
        case "NUMERIC": {
          return formatted.replace(regexPatterns.numeric, "");
        }
        case "LETTERS_WITH_NO_SPACE": {
          return formatted.replaceAll(regexPatterns.space, "");
        }
        case "LETTERS_ONLY": {
          return formatted.replace(regexPatterns.lettersOnly, "");
        }
        case "LETTERS_AND_PERIOD": {
          return formatted.replace(regexPatterns.lettersAndPeriod, "");
        }
        case "ALPHA_NUMERIC": {
          return formatted.replace(regexPatterns.alphaNumeric, "");
        }
        default: {
          if (typeof formatter === "function") {
            return formatter(formatted, values);
          }
          return formatted;
        }
      }
    }, value);
    return formattedValue;
  };

  const validateField = (field: FormField, value: any) => {
    value = value?.trim();
    const fieldSchema = validationSchema[field];
    if (fieldSchema) {
      const validationKeys = Object.keys(fieldSchema);

      for (let key of validationKeys) {
        const schemaItem = fieldSchema[key as keyof ValidationItem];
        if (!containsEmojis(value)) {
          value = format(value, ["CLEAN_EMOJIS"]);
        }
        switch (key) {
          case "formatters": {
            format(value, schemaItem as FormatterTypes[]);
            break;
          }
          case "type": {
            const type = schemaItem;
            // if (type === "string" && typeof value !== "string")
            //   return { status: true, message: "Please enter a valid string" };
            if (type === "number" && typeof value === "number" && !isNaN(value))
              return { status: true, message: "Please enter a valid number" };
            break;
          }
          case "required": {
            if (!Boolean(value))
              return {
                status: true,
                message: schemaItem as string,
              };
            break;
          }
          case "min": {
            if (value.length < (schemaItem as LengthLimitType)?.limit)
              return {
                status: true,
                message: (schemaItem as LengthLimitType).message,
              };
            break;
          }
          case "max": {
            if (value.length > (schemaItem as LengthLimitType).limit)
              return {
                status: true,
                message: (schemaItem as LengthLimitType).message,
              };
            break;
          }
          case "length": {
            if (value.length !== (schemaItem as LengthLimitType).limit)
              return {
                status: true,
                message: (schemaItem as LengthLimitType).message,
              };
            break;
          }
          case "numeric": {
            if (!isNumeric(value)) {
              return {
                status: true,
                message: schemaItem as string,
              };
            }
            break;
          }
          case "onlyLetters": {
            if (regexPatterns.lettersOnly.test(value)) {
              return {
                status: true,
                message: schemaItem as string,
              };
            }
            break;
          }
          case "onlyLettersAndPeriod": {
            if (regexPatterns.lettersAndPeriod.test(value)) {
              return {
                status: true,
                message: schemaItem as string,
              };
            }
            break;
          }
          case "notSpecialCharactersOrNumbersOnly": {
            if (regexPatterns.specialCharactersAndNumbersOnly.test(value)) {
              return {
                status: true,
                message: schemaItem as string,
              };
            }
            break;
          }
          case "emailOrEmptyString": {
            if (!regexPatterns.emailOrEmptyString.test(value)) {
              return {
                status: true,
                message: schemaItem as string,
              };
            }
            break;
          }
          case "email": {
            if (!isValidEmail(value)) {
              return {
                status: true,
                message: schemaItem as string,
              };
            }
            break;
          }
          case "phone": {
            if (isIndianNumber(value)) {
              if (value.length !== 13) {
                return {
                  status: true,
                  message: t("indian_phone_number_digits_limit") as string,
                };
              }
              if (value[3] < 6) {
                return {
                  status: true,
                  message: schemaItem as string,
                };
              }
            }
            if (!isPossiblePhoneNumber(value) && !isValidPhoneNumber(value)) {
              return {
                status: true,
                message: schemaItem as string,
              };
            }
            break;
          }
          case "when": {
            if (typeof schemaItem === "function") {
              return schemaItem(value, values);
            }
            break;
          }
          case "label":
            break;
          default: {
            throw new Error("unexpected schema key, " + key);
          }
        }
      }
    }
  };

  const validate = (values: any) => {
    const validationKeys = Object.keys(validationSchema);
    let hasError = false;
    for (let key of validationKeys) {
      if (Boolean(skipValidation) && (key === "state" || key === "state_code" || key === "zip")) {
        continue;
      }
      const error = validateField(key as FormField, values[key]);
      if (error) {
        setErrors((errs) => ({ ...errs, [key]: error }));
        hasError = true;
      }
    }
    return !hasError;
  };

  function scrollErrorsIntoView() {
    let scrollTo = "";
    for (let item of Object.values(FormField)) {
      if (errors[item] && errors[item]?.status) {
        scrollTo = item;
        break;
      }
    }

    document.getElementById(`flo-field-${scrollTo}`)?.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });

    setShouldScroll(false);
  }

  const previousError = usePrevious(errors);

  React.useEffect(() => {
    if (previousError !== errors && shouldScroll) {
      scrollErrorsIntoView();
    }
  }, [errors]);

  function handleSubmit(callback: (values: any) => void, options = { shouldScrollErrorsIntoView: true }) {
    return function (event: any) {
      event.preventDefault();
      const isValid = validate(values);
      setShouldScroll(true);
      if (!isValid) {
        return;
      }
      callback(values);
    };
  }

  const handleFieldChange = (fieldName: FormField, value: unknown) => {
    setTouched((touched) => ({ ...touched, [fieldName]: true }));
    setValidations((vlds) => ({ ...vlds, [fieldName]: false }));
    const fieldSchema = validationSchema[fieldName];
    value = format(value, ["CLEAN_EMOJIS"]);
    if (fieldSchema?.formatters) {
      value = format(value, fieldSchema.formatters);
    }
    if (errors[fieldName] && !validateField(fieldName, value)) {
      setErrors((errs) => ({ ...errs, [fieldName]: undefined }));
    }
    setValueOf(fieldName, value);
  };

  const inputProps = (
    fieldName: FormField,
    config?: {
      onChange?: (e: any) => void;
      onBlur?: (event: React.FocusEvent<HTMLElement>) => void;
      onFocus?: (event: React.FocusEvent<HTMLElement>) => void;
    },
  ) => {
    const hasCustomOnChange = config?.onChange != null;

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      let value = event.target.value;
      handleFieldChange(fieldName, value);
    };

    const onBlur = (event: React.FocusEvent<HTMLInputElement>): void => {
      if (config?.onBlur != null) {
        config.onBlur(event);
      }

      if (!touched[fieldName]) {
        return;
      }

      const error = validateField(fieldName, values[fieldName]);

      if (error) {
        setErrors((errs) => ({ ...errs, [fieldName]: error }));
      } else if (errors[fieldName]) {
        setErrors((errs) => ({ ...errs, [fieldName]: undefined }));
      } else {
        setValidations((vlds) => ({ ...vlds, [fieldName]: true }));
      }
    };

    const onFocus = (event: React.FocusEvent<HTMLElement>): void => {
      if (config?.onFocus != null) {
        config.onFocus(event);
      }
    };

    return {
      name: fieldName,
      id: fieldName,
      onChange: (hasCustomOnChange ? config.onChange : onChange) as (e: any) => void,
      onBlur,
      onFocus,
      value: values[fieldName] as string,
    };
  };

  return {
    handleSubmit,
    setValues,
    setErrors,
    setValueOf,
    handleFieldChange,
    state: {
      values,
      errors,
      validations,
    },
    inputProps,
  };
}
