import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useLocale } from "lib/hooks/useLocale";
import { DialogBody, DialogHeader, GenericDialog } from "components/common/dialogs/GenericDialog";
import CouponDialog from "components/checkout/dialogs/CouponDialog";
import { AppliedCouponType } from "lib/contexts/CheckoutProvider";
import {
  deleteRequest,
  getRequest,
  putRequest,
  postRequest,
  fetcher,
  staticOptions,
  publicFetcher,
} from "lib/core/apiClient";
import { getItems, findIdCouponByCode, isOnlyFreebieAutoApplied } from "lib/utils/checkout";
import OverlaySpinner from "components/common/loaders/OverlaySpinner";
import { Tag, ArrowRight, ChevronDown, ChevronRight, XCircle } from "react-feather";
import { useAuthContext } from "lib/contexts/AuthProvider";
import useSWR, { mutate } from "swr";
import { errorToast } from "lib/utils/toasters";
import GenericButton from "components/common/buttons/GenericButton";
import InputField from "components/common/forms/InputField";
import useSendAnalyticsEvent from "lib/hooks/useAnalytics";
import { analyticsEvents, eventTypes } from "lib/utils/constants";
import CelebrationPopup from "components/common/popups/CelebrationPopup";
import Price from "components/common/Price";
import SavingsIcon from "assests/images/savings-cookie.svg";
import AppliedItem from "components/common/AppliedItem";
import { useMerchantContext } from "lib/contexts/MerchantProvider";
import { CouponDisplayConfig } from "lib/types/merchant";
import { SavingItemsType, ShippingHandlesType } from "lib/types/checkout";
import { ErrorType } from "lib/types/address";
import { CouponReplacementType } from "lib/types/checkout";
import { useCheckoutContext } from "lib/contexts/CheckoutProvider";
import { useSearchParams } from "react-router-dom";
import { getInvalidityReason, publishPostMessage } from "lib/utils/helpers";
import SwitchCouponDialog from "components/checkout/dialogs/SwitchCouponDialog";
import DiscountBadge from "assests/images/discount-badge.svg?react";

interface CouponsProps {
  context?: "cart" | "checkout";
}

interface AvailableCoupons {
  shopflo: number;
  shopify: number;
}

let isCelebrationPopupFirstRendered = false;

const Coupons: React.FC<CouponsProps> = ({ context = "checkout" }) => {
  const { t } = useLocale();
  const [searchParams] = useSearchParams();
  const page = searchParams.get("page")?.toLowerCase();
  const {
    state: { appliedCoupons, checkoutId, coupons, isC2P, checkoutModal, checkoutView, initialCheckoutStep },
    actions: {
      updateCheckoutBasedOnCheckoutResponse,
      setCoupons,
      setCheckoutItems,
      setCheckoutModal,
      setCheckoutView,
      setShippingHandles,
    },
  } = useCheckoutContext();
  const {
    state: { isAuthenticated, lockDiscounts },
  } = useAuthContext();
  const {
    state: { merchant },
  } = useMerchantContext();

  const [manualCoupon, setManualCoupon] = useState<string>("");
  const { sendAnalyticsEvent } = useSendAnalyticsEvent();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [openCelebration, setOpenCelebration] = useState<boolean>(false);
  const [appliedCouponCode, setAppliedCouponCode] = useState<string>("");
  const [incomingCouponCode, setIncomingCouponCode] = useState<string>("");
  const [couponInputEnabled, setCouponInputEnabled] = useState<boolean>(false);
  const [couponApplyError, setCouponApplyError] = useState<ErrorType>({ status: false, message: "" });
  const [couponReplacementPopupConfig, setCouponReplacementPopupConfig] = useState<CouponReplacementType>({
    showPopup: false,
    appliedDiscountCode: "",
    invalidDiscountCodes: [],
    invalidityReason: "NONE",
  });
  const [showReplacementLoader, setShowReplacementLoader] = useState<boolean>(false);

  const { data: couponsData, isValidating: isCouponsLoading } = useSWR(
    !Boolean(lockDiscounts) && !Boolean(isC2P) && Boolean(checkoutId)
      ? Boolean(isAuthenticated)
        ? `/checkout/v1/checkout/${checkoutId}/discounts`
        : `/v1/checkout/${checkoutId}/discounts`
      : null,
    Boolean(isAuthenticated) ? fetcher : publicFetcher,
    staticOptions,
  );

  useEffect(() => {
    if (getCouponDisplayType() === "STRIP") {
      setCouponInputEnabled(false);
    }
  }, [checkoutView]);

  useEffect(() => {
    if (!Boolean(couponsData) || Boolean(isCouponsLoading)) return;
    setCoupons(couponsData);
  }, [couponsData, isCouponsLoading]);

  useEffect(() => {
    const hasAutoAppliedCoupon = appliedCoupons?.find((coupon) => coupon.autoApplied);
    if (Boolean(hasAutoAppliedCoupon) && !isCelebrationPopupFirstRendered) {
      setIncomingCouponCode(hasAutoAppliedCoupon?.code ?? "");
      setAppliedCouponCode("");
      setOpenCelebration(true);
      isCelebrationPopupFirstRendered = true;
    }
  }, []);

  const savingItems: SavingItemsType = useMemo(() => {
    let count = 0;
    let manualFreebieCount = 0;

    const savings = appliedCoupons?.reduce(
      (
        acc: number,
        { discountValue, isFreebie, autoApplied, discountPercentage, isReward, couponRewardConfig },
      ) => {
        if (!isFreebie) {
          count++;
        }
        if (isFreebie) {
          if (discountPercentage !== 100) {
            count++;
          } else if (!autoApplied) {
            manualFreebieCount++;
            count++;
          }
        }
        if (isReward) {
          const discountAmount = couponRewardConfig?.amount ?? 0;
          return acc + discountAmount;
        }

        return acc + discountValue;
      },
      0,
    );
    return { savings: savings, count: count, manualFreebieCount: manualFreebieCount };
  }, [appliedCoupons, couponInputEnabled, coupons, checkoutView]);

  const renderCouponSummaryStrip = useCallback(
    (showAppliedCount: boolean, showRewards: boolean = false, showSavings: boolean = true) => {
      const { count, manualFreebieCount, savings } = savingItems;
      const rewards = appliedCoupons?.filter((coupon) => coupon.isReward) || [];

      if (savings === 0 && getCouponDisplayType() === "STRIP" && !showRewards) {
        return;
      }
      return (
        <div
          className={`flex w-full flex-col ${!Boolean(coupons?.length) ? "cursor-pointer " : ""}`}
          onClick={() => {
            if (Boolean(getCouponDisplayType() !== "STRIP")) {
              toggleCouponInput();
            }
          }}>
          <div className="flex w-full flex-col divide-y divide-[#2C874A1A]">
            {showRewards &&
              rewards.map((reward) => (
                <div
                  className="flex items-center space-x-2 px-4 py-3 text-sm font-medium text-yay-dark"
                  key={reward.code}>
                  <span className="flex h-4 w-4 items-center justify-center rounded-full border border-[#2C874A80] text-xxs">
                    ₹
                  </span>
                  <span>{reward?.rewardData?.header}</span>
                </div>
              ))}
            {showSavings && savings > 0 && (
              <div
                className={`justify-left relative flex items-center overflow-hidden  py-0.5  ${
                  couponInputEnabled
                    ? "border-b border-yay-light  ease-in-out"
                    : "border-b-0 delay-[650ms] ease-in"
                }`}>
                <img
                  className="spin-slow absolute left-4 top-3 h-4 min-h-4 w-4 min-w-4"
                  src={SavingsIcon}
                  alt="%"
                />
                <div className="flex flex-col py-2.5 pl-10 pr-3">
                  <div className="flex w-full flex-row">
                    <div className="flex w-full flex-row items-center justify-between overflow-hidden pr-2">
                      <p className="flex items-center space-x-1 truncate pr-2 text-sm font-normal text-yay-dark">
                        {manualFreebieCount !== count && (
                          <>
                            <Price total={savings} />
                            &nbsp;
                            {t("total_savings").toLowerCase()}
                          </>
                        )}
                        {showAppliedCount && manualFreebieCount !== count && <>&nbsp;·&nbsp;</>}
                        {showAppliedCount && t("x_coupons_applied", { count })}
                      </p>
                    </div>
                  </div>
                </div>
                {/* Arrow for dropdown when no discounts are availble */}
                {Boolean(getCouponDisplayType() !== "STRIP") && Boolean(appliedCoupons?.length) && (
                  <ChevronDown
                    className={`!ml-auto !mr-4 h-4 w-4 text-coal-dark ${
                      couponInputEnabled
                        ? `rotate-180 transform duration-700 ease-in-out`
                        : `rotate-0 transform duration-700 ease-in-out`
                    }`}
                  />
                )}
              </div>
            )}
          </div>
          {/* Arrow for dropdown when no discounts are availble */}
          <div
            className={`relative  overflow-hidden transition-[max-height] ${
              couponInputEnabled ? `duration-1000 ease-in` : `duration-700 ease-out`
            } ${couponInputEnabled ? `max-h-[9999px]` : ` max-h-0`} `}>
            {appliedCoupons
              ?.filter((coupon) => !(coupon?.isFreebie && coupon?.autoApplied))
              ?.map((coupon: AppliedCouponType, index: number) => {
                return (
                  <AppliedItem
                    key={`${coupon?.code}_${index}`}
                    coupon={coupon}
                    handleDeleteItem={handleDeleteCoupon}
                  />
                );
              })}
          </div>
        </div>
      );
    },
    [appliedCoupons, couponInputEnabled, coupons, checkoutView],
  );

  const getAvailableCouponDetails = useCallback(() => {
    let couponDetails: AvailableCoupons = { shopflo: 0, shopify: 0 };
    if (!Boolean(coupons)) return couponDetails;
    let applicableCoupons = coupons.filter(
      (coupon: any) => coupon.is_applicable || coupon?.un_applicability_reason?.reason === "UARC_002",
    );

    couponDetails.shopify = applicableCoupons.filter((coupon: any) =>
      coupon?.coupon_details?.tags?.includes("SHOPIFY"),
    ).length;

    couponDetails.shopflo = applicableCoupons.filter((coupon: any) =>
      coupon?.coupon_details?.tags?.includes("SHOPFLO"),
    ).length;

    return couponDetails;
  }, [coupons]);

  const removableAppliedCoupon = useMemo(() => {
    if (!merchant?.discountSettings?.showMaxDiscount) return null;
    if (appliedCoupons.length !== 1) return null;
    if (!appliedCoupons[0].isRemovable || appliedCoupons[0].isFreebie) return null;
    return appliedCoupons[0];
  }, [appliedCoupons]);

  const maxAvailableCoupon = useMemo(() => {
    if (!merchant?.discountSettings?.showMaxDiscount) return null;
    if (Boolean(appliedCoupons.length)) return null;
    if (getAvailableCouponDetails().shopflo < 1) return null;
    const applicableCoupons = coupons.filter(
      (coupon) =>
        Boolean(coupon?.is_applicable) &&
        !Boolean(coupon?.coupon_details?.already_applied) &&
        Boolean(coupon?.coupon_details?.coupon_type !== "BXGY"),
    );
    if (applicableCoupons.length < 1) return null;
    return applicableCoupons?.sort((coupon, compareCoupon) =>
      coupon?.total_discount < compareCoupon?.total_discount ? 1 : -1,
    )?.[0];
  }, [coupons, appliedCoupons]);

  const isCouponStripAnimationEnabled = useCallback(() => {
    const { shopflo, shopify } = getAvailableCouponDetails();

    if (shopflo === 0 && shopify === 0) return false;

    const onlyFreebiesApplied = () =>
      appliedCoupons.every((coupon: AppliedCouponType) => coupon?.isFreebie) &&
      Boolean(appliedCoupons?.length);

    return (
      isOnlyFreebieAutoApplied(appliedCoupons) || !Boolean(appliedCoupons?.length) || onlyFreebiesApplied()
    );
  }, [appliedCoupons, getAvailableCouponDetails]);

  const getCouponDisplayType = useCallback(() => {
    //Check if the coupons are enabled for particluar page
    const areCouponsEnabled = Boolean(
      merchant?.couponDisplayConfig?.find((config: CouponDisplayConfig) => {
        if (checkoutView === "ADDRESS_LIST" || page === "cart") {
          return config.type === "ORDER_SUMMARY";
        } else {
          return config.type === checkoutView;
        }
      })?.isDisplayEnabled,
    );

    if (!Boolean(areCouponsEnabled) || merchant?.couponStackLength === 0) return null;

    //If there are no coupons or coupon stack length is 0 then default to Input field
    if (!Boolean(coupons?.length)) return "INPUT";

    //Get the display type of the coupons
    const displayType = merchant?.couponDisplayConfig?.find((config: CouponDisplayConfig) => {
      if (checkoutView === "ADDRESS_LIST" || page === "cart") {
        return config.type === "ORDER_SUMMARY";
      } else {
        return config.type === checkoutView;
      }
    })?.displayType;

    //Conditions for coupon dialog to be disabled
    const isCouponDialogDisabled =
      !merchant?.isDiscountEnabled ||
      (!Boolean(coupons?.length) && coupons.every((coupon: AppliedCouponType) => coupon?.autoApplied));

    if (isCouponDialogDisabled) {
      return null;
    }
    return displayType;
  }, [coupons, merchant, checkoutView, page]);

  const handleDiscountApply = async (
    code: string,
    isManual: boolean = false,
    overrideRemoval: boolean = false,
  ) => {
    if (!Boolean(code?.trim())) return;
    const couponId = findIdCouponByCode(coupons, code);
    //Coupon entered/selected event
    sendAnalyticsEvent({
      eventName: isManual ? analyticsEvents.FLO_COUPON_ENTERED : analyticsEvents.FLO_COUPON_SELECTED,
      eventType: "click",
      metaData: {
        couponData: {
          coupon_code: code,
          coupon_id: Boolean(couponId) ? couponId : "NA",
        },
      },
    });

    const payload = {
      discount_code: code?.trim(),
    };

    try {
      overrideRemoval ? setShowReplacementLoader(true) : setIsLoading(true);
      let response;
      if (isAuthenticated) {
        response = await postRequest(
          `/checkout/v1/checkout/${checkoutId}/discounts${overrideRemoval ? "?raise_on_removal=false" : ""}`,
          payload,
        );
        const discountResponse = await getRequest(`/checkout/v1/checkout/${checkoutId}/discounts`);
        setCoupons(discountResponse);
      } else {
        response = await putRequest(
          `/v1/checkout/${checkoutId}/discounts${overrideRemoval ? "?raise_on_removal=false" : ""}`,
          payload,
          "CHECKOUT_PUBLIC",
        );
        const discountResponse = await getRequest(`/v1/checkout/${checkoutId}/discounts`, "CHECKOUT_PUBLIC");
        setCoupons(discountResponse);
      }
      if (!response) {
        errorToast(t("coupon_not_found"));
        return;
      }
      if (Boolean(response?.metadata?.show_discount_warning)) {
        setCouponReplacementPopupConfig({
          showPopup: Boolean(response?.metadata?.show_discount_warning),
          appliedDiscountCode: code?.trim(),
          invalidDiscountCodes:
            response?.metadata?.invalid_discount_code &&
            Boolean(response?.metadata?.invalid_discount_code?.length)
              ? response?.metadata?.invalid_discount_code
              : [],
          invalidityReason: getInvalidityReason(
            Boolean(response?.metadata?.invalid_discount_reasons?.length)
              ? response?.metadata?.invalid_discount_reasons
              : [],
          ),
        });
        overrideRemoval ? setShowReplacementLoader(false) : setIsLoading(false);
        return;
      }

      // send coupon applied to cart
      if (context === "checkout") {
        publishPostMessage(eventTypes.COUPONS_UPDATED, {});
      }
      setAppliedCouponCode(code);
      setIncomingCouponCode("");
      setManualCoupon("");
      setCouponApplyError((oldValues: ErrorType) => ({ ...oldValues, status: false, message: "" }));

      updateCheckoutBasedOnCheckoutResponse(response);

      overrideRemoval ? setShowReplacementLoader(false) : setIsLoading(false);
      setCheckoutModal("NONE");
      setOpenCelebration(true);

      const isAddressServiceable = response?.pricing?.serviceable ?? false;
      if (!Boolean(isAddressServiceable)) {
        if (Boolean(checkoutView === "PAYMENTS") && Boolean(initialCheckoutStep !== "PAYMENTS")) {
          setCheckoutView("ADDRESS_LIST");
        }
        if (Boolean(checkoutView === "PAYMENTS") && Boolean(initialCheckoutStep === "PAYMENTS")) {
          setCheckoutModal("ADDRESS_LIST");
        }
        errorToast(t("serviceability_error"), 5000);
        return;
      }

      const shippingHandles = response?.metadata?.available_shipping_handles;
      const showShippingHandles = response?.metadata?.show_shipping_handle_selector ?? false;
      setShippingHandles(shippingHandles as ShippingHandlesType);
      if (Boolean(showShippingHandles) && checkoutView === "PAYMENTS" && initialCheckoutStep !== "PAYMENTS") {
        setCheckoutModal("SHIPPING_HANDLES");
      } else {
        mutate(`/checkout/v2/checkout/${checkoutId}/payments`);
        mutate(`UPI_INTENT`);
      }

      //coupon success event
      sendAnalyticsEvent({
        eventName: analyticsEvents.FLO_COUPON_SUCCESS,
        eventType: "flo_action",
        metaData: {
          couponData: {
            coupon_code: code,
            coupon_id: Boolean(couponId) ? couponId : "NA",
          },
        },
      });
    } catch (e: any) {
      overrideRemoval ? setShowReplacementLoader(false) : setIsLoading(false);
      const errorMsg = e?.response?.data?.error;
      if (Boolean(isManual)) {
        setCouponApplyError((oldValues: ErrorType) => ({ ...oldValues, status: true, message: errorMsg }));
      } else {
        errorToast(errorMsg ?? t("coupon_not_found"));
      }
      //coupon failed event
      sendAnalyticsEvent({
        eventName: analyticsEvents.FLO_COUPON_FAILED,
        eventType: "flo_action",
        metaData: {
          couponData: {
            coupon_code: code,
            coupon_id: Boolean(couponId) ? couponId : "NA",
            failed_reason: errorMsg,
          },
        },
      });
      console.error(e);
    }
  };

  const handleDeleteCoupon = async (couponToBeDeleted: string) => {
    if (!Boolean(appliedCoupons?.find((coupon) => coupon?.code === couponToBeDeleted))) return;
    const payload = {
      discount_code: couponToBeDeleted,
    };

    try {
      setIsLoading(true);
      let response: any;
      let discountResponse: any;
      if (isAuthenticated) {
        response = await deleteRequest(`/checkout/v1/checkout/${checkoutId}/discounts`, payload);
        discountResponse = await getRequest(`/checkout/v1/checkout/${checkoutId}/discounts`);
        setCoupons(discountResponse);
      } else {
        response = await deleteRequest(`/v1/checkout/${checkoutId}/discounts`, payload, "CHECKOUT_PUBLIC");
        const discountResponse = await getRequest(`/v1/checkout/${checkoutId}/discounts`, "CHECKOUT_PUBLIC");
        setCoupons(discountResponse);
      }

      updateCheckoutBasedOnCheckoutResponse(response);

      setIsLoading(false);

      if (!Boolean(response?.discounts)) {
        setManualCoupon("");
      }
      if (Boolean(response?.items)) {
        setCheckoutItems(getItems(response?.items));
      }
      const couponId = findIdCouponByCode(coupons, couponToBeDeleted);
      //coupon success event
      sendAnalyticsEvent({
        eventName: analyticsEvents.FLO_COUPON_REMOVED,
        eventType: "flo_action",
        metaData: {
          couponData: {
            coupon_code: couponToBeDeleted,
            coupon_id: Boolean(couponId) ? couponId : "NA",
          },
        },
      });

      const shippingHandles = response?.metadata?.available_shipping_handles;
      const showShippingHandles = response?.metadata?.show_shipping_handle_selector ?? false;
      setShippingHandles(shippingHandles as ShippingHandlesType);
      if (Boolean(showShippingHandles) && checkoutView === "PAYMENTS" && initialCheckoutStep !== "PAYMENTS") {
        setCheckoutModal("SHIPPING_HANDLES");
      } else {
        mutate(`/checkout/v2/checkout/${checkoutId}/payments`);
        mutate(`UPI_INTENT`);
      }
    } catch (e) {
      setIsLoading(false);
      errorToast(t("delete_discount_error"));
      console.error(e);
    }
  };

  const toggleCouponInput = () => {
    setCouponInputEnabled(!couponInputEnabled);
  };

  const closeSwitchDiscountPopup = () => {
    setCouponReplacementPopupConfig({
      showPopup: false,
      appliedDiscountCode: "",
      invalidDiscountCodes: [],
      invalidityReason: "NONE",
    });
  };

  const isBackgroundGradientEnabled = () => {
    if (!Boolean(appliedCoupons?.length)) return false;
    if (Boolean(couponApplyError?.status)) return false;
    if (Boolean(isOnlyFreebieAutoApplied(appliedCoupons))) return false;
    return true;
  };
  if (Boolean(isC2P) && !Boolean(appliedCoupons?.length)) return <></>;

  if (Boolean(isCouponsLoading))
    return (
      <div className="relative flex min-h-[56px] items-center rounded-2xl border border-gray-light px-3">
        <Tag className="left-4 h-4 w-4 text-gray-dark" strokeWidth={2.5} />
        <div className="flex flex-col pl-2">
          <h3 className="shimmer text-sm font-normal text-gray-dark">{t("loading_coupons")}</h3>
        </div>
      </div>
    );
  if (!Boolean(merchant?.isDiscountEnabled) && Boolean(appliedCoupons?.length))
    return <div className="rounded-2xl bg-yay-lighter">{renderCouponSummaryStrip(true, true)}</div>;
  return (
    <>
      <div
        className={`flex flex-col items-start justify-center rounded-2xl bg-none text-coal-dark ${
          Boolean(getCouponDisplayType())
            ? isBackgroundGradientEnabled()
              ? "bg-yay-lighter"
              : ""
            : "bg-yay-lighter"
        }`}>
        {!Boolean(isC2P) &&
          Boolean(getCouponDisplayType()) &&
          (getCouponDisplayType() === "STRIP" ? (
            <div
              className={`isolate flex w-full flex-col !overflow-hidden rounded-2xl border-[2px] border-yay-light ${
                isCouponStripAnimationEnabled() ? "text-yay-dark" : "text-coal-dark"
              }`}>
              {removableAppliedCoupon && Boolean(removableAppliedCoupon) ? (
                <div
                  className={`relative flex  h-[3.25rem] w-full flex-row items-center justify-between  rounded-2xl bg-white px-3 py-4`}
                  id="flo_coupons_applied">
                  {/* Animation div */}
                  {isCouponStripAnimationEnabled() && (
                    <div
                      className="absolute left-0 top-0 h-full w-full animate-slide
                    bg-gradient-to-l from-[#2b874a03] from-0% via-[#2c874a12] via-[#2c874a12] via-[#2c874a21] via-[#2c874a21] via-20% via-40% via-60% via-80% to-[#2c874a03] to-100% !bg-[length:30%_100%] !bg-no-repeat"></div>
                  )}
                  {/* Animation div */}

                  <div
                    className="flex w-5/6 items-center space-x-3 cursor-pointer"
                    onClick={() => {
                      setCheckoutModal("DISCOUNT_LIST");
                      setCouponApplyError((oldValues: ErrorType) => ({
                        ...oldValues,
                        status: false,
                        message: "",
                      }));
                    }}>
                    <img className="spin-slow h-4 min-h-4 w-4 min-w-4" src={SavingsIcon} alt="%" />
                    <div className="flex flex-col space-y-1">
                      <h3 className="flex text-sm font-medium text-yay-dark items-center space-x-1 truncate pr-2">
                        {savingItems.manualFreebieCount !== savingItems.count && (
                          <>
                            <Price total={savingItems.savings} />
                            &nbsp;
                            {t("total_savings").toLowerCase()}
                          </>
                        )}
                        {savingItems.manualFreebieCount !== savingItems.count && <>&nbsp;·&nbsp;</>}
                        {t("x_coupons_applied", { count: savingItems.count })}
                      </h3>
                      <div className="text-xs text-coal-light font-normal flex items-end cursor-pointer">
                        <span>
                          {Boolean(getAvailableCouponDetails()?.shopflo)
                            ? t("x_coupons_available", {
                                count: getAvailableCouponDetails()?.shopflo,
                                symbol: getAvailableCouponDetails()?.shopify > 0 ? "+" : "",
                              })
                            : t("view_all_coupons")}
                        </span>
                        <ChevronRight className="w-3 h-3" />
                      </div>
                    </div>
                  </div>

                  {/* Always show coupon list opening chevron */}
                  <span
                    onClick={() => handleDeleteCoupon(removableAppliedCoupon?.code)}
                    className="text-xs font-medium text-coal-dark underline cursor-pointer">
                    {t("remove")}
                  </span>
                </div>
              ) : maxAvailableCoupon && Boolean(maxAvailableCoupon) ? (
                <div
                  className={`relative flex  h-[3.25rem] w-full flex-row items-center justify-between  rounded-2xl bg-white px-3 py-4`}
                  id="flo_coupons_applied">
                  {/* Animation div */}
                  {isCouponStripAnimationEnabled() && (
                    <div
                      className="absolute left-0 top-0 h-full w-full animate-slide
                    bg-gradient-to-l from-[#2b874a03] from-0% via-[#2c874a12] via-[#2c874a12] via-[#2c874a21] via-[#2c874a21] via-20% via-40% via-60% via-80% to-[#2c874a03] to-100% !bg-[length:30%_100%] !bg-no-repeat"></div>
                  )}
                  {/* Animation div */}

                  <div
                    className="flex w-5/6 items-center space-x-3 cursor-pointer"
                    onClick={() => {
                      setCheckoutModal("DISCOUNT_LIST");
                      setCouponApplyError((oldValues: ErrorType) => ({
                        ...oldValues,
                        status: false,
                        message: "",
                      }));
                    }}>
                    <img className="spin-slow h-4 min-h-4 w-4 min-w-4" src={SavingsIcon} alt="%" />
                    <div className="flex flex-col space-y-1">
                      <h3 className="text-sm font-medium text-yay-dark">{`${
                        Boolean(maxAvailableCoupon.coupon_details?.header)
                          ? maxAvailableCoupon.coupon_details?.header + " with "
                          : ""
                      }${maxAvailableCoupon?.coupon_details?.coupon_code}`}</h3>
                      <div className="text-xs text-coal-light font-normal flex items-end cursor-pointer">
                        <span>
                          {Boolean(getAvailableCouponDetails()?.shopflo)
                            ? t("x_coupons_available", {
                                count: getAvailableCouponDetails()?.shopflo,
                                symbol: getAvailableCouponDetails()?.shopify > 0 ? "+" : "",
                              })
                            : t("view_all_coupons")}
                        </span>
                        <ChevronRight className="w-3 h-3" />
                      </div>
                    </div>
                  </div>

                  {/* Always show coupon list opening chevron */}
                  <span
                    onClick={() => handleDiscountApply(maxAvailableCoupon?.coupon_details?.coupon_code)}
                    className="text-xs font-medium text-yay-dark underline cursor-pointer">
                    {t("apply")}
                  </span>
                </div>
              ) : (
                <div
                  className={`relative flex  h-[3.25rem] w-full	cursor-pointer flex-row items-center justify-between  rounded-2xl bg-white px-3 py-4`}
                  id="flo_coupons_select"
                  onClick={() => {
                    setCheckoutModal("DISCOUNT_LIST");
                    setCouponApplyError((oldValues: ErrorType) => ({
                      ...oldValues,
                      status: false,
                      message: "",
                    }));
                  }}>
                  {/* Animation div */}
                  {isCouponStripAnimationEnabled() && (
                    <div
                      className="absolute left-0 top-0 h-full w-full animate-slide
                    bg-gradient-to-l from-[#2b874a03] from-0% via-[#2c874a12] via-[#2c874a12] via-[#2c874a21] via-[#2c874a21] via-20% via-40% via-60% via-80% to-[#2c874a03] to-100% !bg-[length:30%_100%] !bg-no-repeat"></div>
                  )}
                  {/* Animation div */}
                  {Boolean(appliedCoupons?.length) &&
                  Boolean(!isOnlyFreebieAutoApplied(appliedCoupons)) &&
                  merchant?.discountSettings?.showMaxDiscount ? (
                    <>
                      <div
                        className="flex w-5/6 items-center space-x-3 cursor-pointer"
                        onClick={() => {
                          setCheckoutModal("DISCOUNT_LIST");
                          setCouponApplyError((oldValues: ErrorType) => ({
                            ...oldValues,
                            status: false,
                            message: "",
                          }));
                        }}>
                        <img className="spin-slow h-4 min-h-4 w-4 min-w-4" src={SavingsIcon} alt="%" />
                        <div className="flex w-full flex-col space-y-1">
                          <h3 className="flex text-sm font-medium text-yay-dark items-center space-x-1 truncate pr-2">
                            {savingItems.manualFreebieCount !== savingItems.count && (
                              <>
                                <Price total={savingItems.savings} />
                                &nbsp;
                                {t("total_savings").toLowerCase()}
                              </>
                            )}
                            {savingItems.manualFreebieCount !== savingItems.count && <>&nbsp;·&nbsp;</>}
                            {t("x_coupons_applied", { count: savingItems.count })}
                          </h3>
                          <div className="text-xs text-coal-light font-normal flex items-end cursor-pointer">
                            <span>
                              {Boolean(getAvailableCouponDetails()?.shopflo)
                                ? t("x_coupons_available", {
                                    count: getAvailableCouponDetails()?.shopflo,
                                    symbol: getAvailableCouponDetails()?.shopify > 0 ? "+" : "",
                                  })
                                : t("view_all_coupons")}
                            </span>
                            <ChevronRight className="w-3 h-3" />
                          </div>
                        </div>
                      </div>
                      {/* Always show coupon list opening chevron */}
                      {<ChevronRight className="h-4 w-4" />}
                    </>
                  ) : (
                    <>
                      <div className="flex w-3/5 items-start space-x-2 ">
                        <Tag className="h-4 w-4" strokeWidth={2.5} />
                        <div className="flex flex-col ">
                          <h3 className="text-sm font-medium">{t("coupons")}</h3>
                        </div>
                      </div>
                      {getAvailableCouponDetails()?.shopflo > 0 && (
                        <div className="ml-auto pr-1 text-sm font-normal text-yay-dark">
                          {t("x_symbol_available", {
                            count: getAvailableCouponDetails()?.shopflo,
                            symbol: getAvailableCouponDetails()?.shopify > 0 ? "+" : "",
                          })}
                        </div>
                      )}
                      {/* Always show coupon list opening chevron */}
                      {<ArrowRight className="h-4 w-4" />}
                    </>
                  )}
                </div>
              )}
            </div>
          ) : (
            <div
              className={`border-box relative flex w-full gap-1.5 px-0 ${
                !Boolean(coupons?.length) && Boolean(appliedCoupons?.length) ? "pb-0 pt-0" : "py-1.5"
              }`}>
              <Tag
                className={`absolute left-4 z-10 h-4 w-4 text-coal-dark ${
                  !Boolean(coupons?.length) && Boolean(appliedCoupons?.length) ? "top-5" : "top-[1.65rem]"
                }`}
                strokeWidth={2.5}
              />
              <InputField
                type="text"
                placeholder={t("enter_coupon_code")}
                name="coupon-code"
                id="coupon-code"
                maxLength={50}
                autoComplete="off"
                autoFocus={false}
                value={manualCoupon}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setManualCoupon(e.target.value.trim())}
                onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) =>
                  e.key === "Enter" && handleDiscountApply(manualCoupon, true)
                }
                customClass={`h-[3.25rem] border border-gray-light !rounded-2xl  placeholder-gray-dark text-sm font-normal text-coal-light w-full !pl-[2.4rem]  ${
                  Boolean(manualCoupon?.length) ? "!rounded-r-lg" : ""
                }`} // on focus remove 2px padding from left to avoid placeholder shifing because of the ring
                showLabel={false}
                tabIndex={-1}
                error={couponApplyError}
              />
              {Boolean(manualCoupon?.length) && (
                <GenericButton
                  buttonText=""
                  height="h-12"
                  width=""
                  customClass="flex items-center justify-center rounded-l-lg  w-14 rounded-2xl bg-primary-dark"
                  iconComponent={<ArrowRight className="h-4 w-4 text-btnPrimaryText" />}
                  onClick={() => handleDiscountApply(manualCoupon, true)}
                />
              )}
            </div>
          ))}
        {Boolean(appliedCoupons?.length) && Boolean(!isOnlyFreebieAutoApplied(appliedCoupons)) && (
          <>
            {renderCouponSummaryStrip(
              true,
              true,
              getCouponDisplayType() !== "STRIP" || !merchant?.discountSettings?.showMaxDiscount,
            )}
          </>
        )}

        {Boolean(openCelebration) && (
          <CelebrationPopup
            isOpen={true}
            closePopup={() => {
              setOpenCelebration(false);
              setAppliedCouponCode("");
              setIncomingCouponCode("");
            }}
            appliedCouponCode={appliedCouponCode}
            incomingCouponCode={incomingCouponCode}
          />
        )}
        <GenericDialog
          isOpen={couponReplacementPopupConfig?.showPopup}
          translateAxis="y"
          customClass="overflow-scroll md:!top-auto md:absolute"
          dialogOverlay={true}
          modalType="REPLACE_COUPON">
          <SwitchCouponDialog
            appliedDiscountCode={couponReplacementPopupConfig?.appliedDiscountCode}
            invalidDiscountCodes={couponReplacementPopupConfig?.invalidDiscountCodes}
            closePopup={closeSwitchDiscountPopup}
            applyCoupon={async () => {
              closeSwitchDiscountPopup();
              await handleDiscountApply(couponReplacementPopupConfig?.appliedDiscountCode, false, true);
            }}
            invalidReason={couponReplacementPopupConfig?.invalidityReason}
          />
        </GenericDialog>
        <GenericDialog
          isOpen={showReplacementLoader}
          translateAxis="y"
          customClass="overflow-scroll md:!top-auto md:absolute"
          dialogOverlay={true}
          modalType="PROCESSING">
          <DialogHeader>
            <div className="flex h-full w-full flex-row items-center justify-between">
              <h1 className="text-base font-medium"> {t("replacing_coupons")}</h1>
            </div>
          </DialogHeader>
          <DialogBody>
            <div className="h-[5px] w-full overflow-hidden bg-carbon-lighter">
              <div className="progress left-right h-full w-full bg-primary-dark"></div>
            </div>
          </DialogBody>
        </GenericDialog>

        {isLoading && <OverlaySpinner />}

        <GenericDialog
          isOpen={checkoutModal === "DISCOUNT_LIST"}
          setIsOpen={(value: boolean) => {
            setCheckoutModal(value ? "DISCOUNT_LIST" : "NONE");
          }}
          translateAxis="x"
          modalType="DISCOUNT_LIST">
          <CouponDialog
            setOpenDialog={() => {
              setCheckoutModal("NONE");
            }}
            handleDiscountApply={handleDiscountApply}
            handleDeleteCoupon={handleDeleteCoupon}
            renderCouponSummaryStrip={renderCouponSummaryStrip}
            isCouponsLoading={isCouponsLoading}
            couponApplyError={couponApplyError}
            setCouponApplyError={setCouponApplyError}
          />
        </GenericDialog>
      </div>
    </>
  );
};

export default Coupons;
