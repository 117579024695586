import React, { useState } from "react";
import DiscountBadge from "assests/images/savings-cookie.svg?react";
import { useLocale } from "lib/hooks/useLocale";
import { ChevronDown } from "react-feather";

interface CalloutCardProps {
  isApplicable: boolean;
  couponTitle: string;
  couponHeader: string;
  couponDescription: string;
  couponLongDescription: string[];
  unApplicabilityReason: string;
}

const CalloutCard: React.FC<CalloutCardProps> = React.memo(
  ({
    isApplicable,
    couponTitle,
    couponHeader,
    couponDescription,
    couponLongDescription,
    unApplicabilityReason,
  }) => {
    const { t } = useLocale();
    const [showMore, setShowMore] = useState<boolean>(false);

    const greyedOut = !isApplicable;
    return (
      <div
        className={`group relative box-border flex h-full min-h-[10rem] w-full flex-col items-start overflow-hidden rounded-2xl border-[1px] border-gray-light`}>
        <div
          className={`relative flex w-full flex-row items-center justify-between overflow-hidden rounded-t-lg bg-gradient-to-b ${
            greyedOut ? "from-gray-lighter to-white" : "from-primary-lighter to-white"
          }`}>
          {/* Coupon Ticket */}
          <div className={`flex w-full flex-row items-center py-2 pl-3 pr-2`}>
            {/* If no brand logo we will use this logo */}
            {/* { brandLogo ? <img src={BrandLogo} alt="Brand Logo" className="object-contain object-center w-8 h-8 pt-1" /> : */}
            <div className="flex h-8 w-8 items-center justify-start">
              <DiscountBadge
                className={`mr-2 h-6 w-6 ${
                  greyedOut ? "stroke-gray-dark" : "stroke-primary-dark"
                } text-coal-light`}
              />
            </div>

            <h3 className="truncate pr-2 text-base font-semibold text-coal-dark">{couponTitle}</h3>
          </div>
          <div
            className={`absolute -bottom-1 w-full border-b-[6px] border-dashed border-primary-lighter ${
              greyedOut ? "border-gray-lighter" : "border-primary-lighter border-opacity-20"
            }`}
          />
        </div>
        <div className="group relative flex min-h-[6.5rem] w-full flex-col items-start justify-between space-y-2 overflow-x-hidden p-3">
          <div className="flex flex-col">
            <div className="flex flex-col space-y-1">
              <h3 className="overflow-x-hidden text-base font-semibold text-coal-dark">{couponHeader}</h3>
              <p className="text-sm font-normal text-coal-light">{couponDescription}</p>
            </div>
          </div>
          {greyedOut && Boolean(unApplicabilityReason) && (
            <p className="text-xs font-medium text-ouch">{unApplicabilityReason}</p>
          )}
          {showMore ? (
            <ul className="flex w-full flex-col space-y-1 rounded-lg bg-gray-lighter px-2 py-3">
              {couponLongDescription?.map((point: string, index: number) => (
                <li
                  className="ml-4 list-disc text-xs font-normal text-coal-light"
                  key={`Callout-Card-${index}`}>
                  <p>{point}</p>
                </li>
              ))}
            </ul>
          ) : (
            <button
              onClick={() => setShowMore(true)}
              className="inline-flex w-fit items-center justify-center space-x-2 text-sm font-medium text-coal-dark outline-none">
              {t("show_details")}
              <ChevronDown className="h-4 w-4" />
            </button>
          )}
        </div>
      </div>
    );
  },
);

export default CalloutCard;
