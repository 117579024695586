import React from "react";
import visaLogo from "assests/images/visa-logo.png";
import amexLogo from "assests/images/amex.png";
// import dinersLogo from "assests/images/diners-club.png";
import mastercardLogo from "assests/images/mastercard.png";
import rupayLogo from "assests/images/rupay-logo.png";
import upiLogo from "assests/images/upi-logo.png";

interface PaymentGatewaysProps {}

interface GatewayType {
  id: string;
  logo: any;
}

const PaymentGateways: React.FC<PaymentGatewaysProps> = ({}) => {
  const gateways: GatewayType[] = [
    {
      id: "visa",
      logo: visaLogo,
    },
    {
      id: "upi",
      logo: upiLogo,
    },
    {
      id: "rupay",
      logo: rupayLogo,
    },
    {
      id: "mastercard",
      logo: mastercardLogo,
    },
    // {
    //   id: "dinersclub",
    //   logo: dinersLogo,
    // },
    {
      id: "amex",
      logo: amexLogo,
    },
  ];
  return (
    <div className="flex flex-row space-x-1">
      {gateways.map((gateway) => (
        <LogoComponent id={gateway.id} logo={gateway.logo} key={gateway.id} />
      ))}
    </div>
  );
};

const LogoComponent: React.FC<GatewayType> = ({ id, logo }) => {
  return <img src={logo} alt={id} className="h-8 w-10 object-contain" />;
};

export const NetworkLogos: React.FC<{}> = () => {
  const networks: GatewayType[] = [
    {
      id: "visa",
      logo: visaLogo,
    },

    {
      id: "rupay",
      logo: rupayLogo,
    },
    {
      id: "mastercard",
      logo: mastercardLogo,
    },
    // {
    //   id: "dinersclub",
    //   logo: dinersLogo,
    // },
    {
      id: "amex",
      logo: amexLogo,
    },
  ];
  return (
    <div className="flex flex-row space-x-0.5">
      {networks.map((network) => (
        <LogoComponent id={network.id} logo={network.logo} key={network.id} />
      ))}
    </div>
  );
};

export default PaymentGateways;
