import { UpSellProductType, UpSellVariantType } from "lib/types/upsell";

type ParseUpsellDataReturnType = {
  discountedUpsells: UpSellProductType[];
  nonDiscountedUpsells: UpSellProductType[];
};

export const parseUpSellData = (upsellResponse: any): ParseUpsellDataReturnType => {
  if (!upsellResponse || !Boolean(upsellResponse)) {
    return {
      discountedUpsells: [],
      nonDiscountedUpsells: [],
    };
  }

  const nonDiscountedUpsells: UpSellProductType[] = [];
  const discountedUpsells: UpSellProductType[] = [];

  try {
    upsellResponse?.forEach((product: any) => {
      const variantImages: Record<string, string> = {};
      const defaultImage = product?.images?.find((image: any) => image?.is_primary)?.src;
      product?.images?.forEach((image: any, ind: number) => {
        image?.linked_variant_ids?.forEach((variantId: string, ind: number) => {
          variantImages[variantId] = image?.src;
        });
      });
      const variants: UpSellVariantType[] = product?.variants
        ?.filter((variant: any) => !Boolean(variant?.out_of_stock))
        ?.map((variant: any, ind: number) => {
          return {
            variantId: variant?.variant_id,
            name: variant?.name,
            inventoryQuantity: variant?.inventory_quantity,
            inventoryPolicy: variant?.inventory_policy ?? "DENY",
            currentPrice: variant?.current_price,
            originalPrice: variant?.original_price,
            imageLink: variantImages[variant?.variant_id] ?? defaultImage,
            outOfStock: Boolean(variant?.out_of_stock),
            currency: variant?.currency,
            taxable: Boolean(variant?.taxable),
            requiresShipping: Boolean(variant?.requires_shipping),
            createdAt: variant?.created_at,
            updatedAt: variant?.updated_at,
            isDiscountedUpsell: Boolean(variant?.upsell_discount_applied),
            discountedPrice: variant?.upsell_discounted_price,
          };
        });
      if (!Boolean(product?.all_variants_out_of_stock)) {
        const upsellToPush = {
          productId: product?.product_id,
          name: product?.name,
          variants: variants,
          allVariantOutOfStock: Boolean(product?.all_variants_out_of_stock),
          upsellIds: Boolean(product?.upsell_ids?.length) ? product?.upsell_ids : [],
          createdAt: product?.created_at,
          updatedAt: product?.updated_at,
        };
        if (Boolean(product?.discounted_upsell_present)) {
          discountedUpsells?.push(upsellToPush);
        } else {
          nonDiscountedUpsells?.push(upsellToPush);
        }
      }
    });
  } catch (e) {
    console.error(e);
  }

  return {
    discountedUpsells,
    nonDiscountedUpsells,
  };
};

export const parseUpsellIds = (upsellResponse: any): Array<string> => {
  const upsellIds: Array<string> = [];
  upsellResponse?.forEach((product: any) => {
    if (!Boolean(product?.upsell_ids?.length)) return;
    product?.upsell_ids?.forEach((upsell_id: string) => {
      if (!Boolean(upsellIds.some((upsellId: string) => upsellId === upsell_id))) {
        upsellIds.push(upsell_id);
      }
    });
  });
  return upsellIds;
};
