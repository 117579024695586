import React, { Fragment, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import GenericButton from "components/common/buttons/GenericButton";
import { X } from "react-feather";
import { CheckoutModalType } from "lib/types/checkout";
import { useCheckoutContext } from "lib/contexts/CheckoutProvider";

interface ConfirmationPopupProps {
  popupTitle: string | JSX.Element;
  popupBody: string | JSX.Element;
  primaryBtnText?: string;
  onClickPrimaryBtn: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onClickSecondaryBtn?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  isOpen: boolean;
  closePopup: () => void;
  cancelBtnText?: string;
  btnsFlexDirection?: "flex-row" | "flex-col";
  modalType: CheckoutModalType;
  showSecondaryBtn?: boolean;
  id?: string;
}

const ConfirmationPopup: React.FC<ConfirmationPopupProps> = ({
  popupTitle,
  popupBody,
  primaryBtnText = "Confirm",
  onClickPrimaryBtn,
  isOpen = false,
  closePopup,
  cancelBtnText = "Cancel",
  onClickSecondaryBtn = closePopup,
  btnsFlexDirection = "flex-row",
  modalType,
  showSecondaryBtn = true,
  id,
}) => {
  const {
    state: { checkoutModal },
    actions: { setCheckoutModal },
  } = useCheckoutContext();

  const browserBackBtnHandler = (e: any) => {
    if (!Boolean(isOpen)) return;
    if (!Boolean(modalType)) return;
    if (modalType === "EXIT_CONFIRMATION") return;
    e.preventDefault();
    closePopup && closePopup();
  };

  // useBackButton(browserBackBtnHandler);

  useEffect(() => {
    if (!Boolean(isOpen)) return;
    if (!Boolean(modalType)) return;

    //Nested modals will have the modalType of their parent.
    if (checkoutModal === "NONE") {
      setCheckoutModal(modalType);
      return () => {
        setCheckoutModal("NONE");
      };
    }
  }, [isOpen]);

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 z-50 overflow-y-auto" onClose={() => {}}>
          <Dialog.Overlay className="fixed inset-0 bg-black opacity-40" />
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-300"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95">
              <div
                className="inline-block max-h-80 w-full max-w-[23rem] transform overflow-hidden rounded-lg bg-white text-left align-middle shadow-xl transition-all"
                id={id}>
                <div className="border-b p-4">
                  <Dialog.Title as="div" className="flex w-full justify-between ">
                    {Boolean(popupTitle) &&
                      (typeof popupTitle === "string" ? (
                        <h1 className="text-base font-medium text-carbon-dark">{popupTitle}</h1>
                      ) : (
                        <>{popupTitle}</>
                      ))}
                    <X className="h-6 w-6 cursor-pointer text-coal-dark" onClick={closePopup} />
                  </Dialog.Title>
                  <div className="pt-3.5">
                    {Boolean(popupBody) &&
                      (typeof popupBody === "string" ? (
                        <p className="text-sm font-normal text-coal-dark">{popupBody}</p>
                      ) : (
                        <>{popupBody}</>
                      ))}
                  </div>
                </div>

                <div
                  className={`flex ${btnsFlexDirection} items-end justify-evenly ${
                    btnsFlexDirection === "flex-col" ? `space-y-4` : ""
                  } ${btnsFlexDirection === "flex-row" ? `space-x-4` : ""} p-4`}>
                  <GenericButton
                    id={checkoutModal === "COD_CONFIRMATION" ? "flo__payments__confirmCOD" : ""}
                    height="h-14"
                    width="w-full"
                    customClass={`flex items-center justify-center text-base font-medium rounded-lg text-btnPrimaryText bg-primary-dark`}
                    buttonText={primaryBtnText}
                    onClick={(event: React.MouseEvent<HTMLButtonElement>) => onClickPrimaryBtn(event)}
                  />

                  {showSecondaryBtn && (
                    <GenericButton
                      height="h-14"
                      width="w-full"
                      customClass="flex items-center justify-center text-base font-medium rounded-lg text-coal-dark border-2 border-gray-light"
                      buttonText={cancelBtnText}
                      onClick={onClickSecondaryBtn}
                    />
                  )}
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default ConfirmationPopup;
