import { PaymentMethodsType, RzpOptionsCustom, RzpPaymentModes, RzpOptionsDefault } from "lib/types/payments";
import { loadScript } from "lib/utils/helpers";

export const loadRZPCustomSdk = async (key: string, pgAccountId?: string, isRzpPref: boolean = true) => {
  try {
    const response = await loadScript("https://checkout.razorpay.com/v1/razorpay.js", async () => {
      (window as any).RzpCustomSdk = (window as any).Razorpay; //Razorpay.js sdk object
      if (isRzpPref) {
        await onRZPCustomSdkLoad(key, pgAccountId);
      }
    });
    return response;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

const onRZPCustomSdkLoad = (key: string, pgAccountId?: string) =>
  new Promise((resolve, reject) => {
    try {
      (window as any).RzpCustomImpl = new (window as any).Razorpay({
        key,
        ...(Boolean(pgAccountId) && { account_id: pgAccountId }),
      }); //Instance of Razorpay.js sdk object initiated with shopflo key
      const razorpay = (window as any).RzpCustomImpl;
      razorpay.once("ready", (rzpPreferences: any) => {
        (window as any).RazorpayPaymentMethods = rzpPreferences?.methods;
        return resolve(rzpPreferences);
      });
    } catch (e) {
      throw reject(e);
    }
  });

export const loadRZPDefaultSDK = async () => {
  if (Boolean((window as any).RzpDefaultImpl)) return {};
  try {
    const response = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js",
      () =>
        new Promise((resolve, reject) => {
          try {
            (window as any).RzpDefaultImpl = (window as any).Razorpay;
            return resolve({});
          } catch (e) {
            throw reject(e);
          }
        }),
    );
    return response;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const getPaymentMethods = (methods: any) => {
  const paymentMethods: PaymentMethodsType = {
    allowedPaymentMethods: {
      card: methods.card,
      cod: methods.cod,
      credit_card: methods.credit_card,
      debit_card: methods.debit_card,
      emi: methods.emi,
      netbanking: methods.netbanking,
      upi: methods.upi,
      wallet: methods.wallet,
    },
    wallets: methods.wallet,
    netbankingBanks: methods.netbanking,
  };
  return paymentMethods;
};

/**
 * Method to start Razorpay payment gateway.
 * This method if for custom implementation of Razorpay
 *
 * @param  {RzpGatewayType} gatewayType       The payment gateway type that will be loaded.
 */
export const initializeRzpCustomPayment = ({
  amount,
  currency = "INR",
  rzpId,
  method,
  userId,
  checkoutId,
  merchantId,
  merchantName,
  userPhone,
  userEmail,
  onFailureHandler,
  redirectUrl,
  accountId,
  methodPayload,
  ...rest
}: RzpOptionsCustom) =>
  new Promise((resolve, reject) => {
    const paymentMethodPayload = getPaymentMethodPayload(method, methodPayload);
    const paymentData: any = {
      amount: Math.round(parseInt((amount * 100).toFixed(2))),
      currency,
      order_id: rzpId,
      method,
      email: userEmail,
      contact: userPhone,
      customer_id: userId,
      ...(Boolean(accountId) && { account_id: accountId }),
      ...paymentMethodPayload,
    };
    if (Boolean(redirectUrl)) {
      paymentData.callback_url = redirectUrl;
    }

    try {
      const razorpay = (window as any).RzpCustomImpl;
      razorpay.createPayment(paymentData);
      razorpay.focus();
      razorpay.on("payment.error", function (e: any) {
        onFailureHandler();
        return reject(e);
      });
      return resolve({});
    } catch (e) {
      return reject(e);
    }
  });

const getPaymentMethodPayload = (method: RzpPaymentModes, methodPayload: any) => {
  let payload = {};
  switch (method) {
    case "card": {
      payload = {
        "card[number]": methodPayload?.card?.number?.toString(),
        "card[expiry_month]": methodPayload?.card?.expiry_month,
        "card[expiry_year]": methodPayload?.card?.expiry_year,
        "card[cvv]": methodPayload?.card?.cvv?.toString(),
        "card[name]": methodPayload?.card?.name?.toString(),
      };
      break;
    }
    case "netbanking": {
      payload = {
        bank: methodPayload?.bank,
      };
      break;
    }
    case "wallet": {
      payload = {
        wallet: methodPayload?.wallet,
      };
      break;
    }
  }

  return payload;
};

/**
 * Method to start Native Razorpay payment gateway.
 * This method if for Native implementation of Razorpay.
 * Invoke this method if the device is not compatible with Flo Custom implementation
 *
 * @param  {RzpOptionsDefault} RzpOptionsDefaultObj      Razorpay default gateway config object
 *
 */
export const initializeRzpDefaultPayment = async ({
  amount,
  currency = "INR",
  rzpId,
  merchantName,
  brandLogoUrl,
  brandThemeColor = "#5a31f4",
  userName,
  userPhone,
  userEmail,
  method,
  onSuccessHandler,
  redirectUrl,
}: RzpOptionsDefault) =>
  new Promise((resolve, reject) => {
    try {
      let options: any = {
        amount: Math.round(parseInt((amount * 100).toFixed(2))),
        currency: currency,
        name: merchantName,
        image: brandLogoUrl,
        order_id: rzpId,
        handler: function (response: any) {
          onSuccessHandler(method, response);
          return resolve({});
        },
        prefill: {
          name: userName,
          email: userEmail,
          contact: userPhone,
          method: method,
        },
        theme: {
          color: brandThemeColor,
        },
        config: displayConfigRzp(method),
      };
      if (Boolean(redirectUrl)) {
        options.callback_url = redirectUrl;
        options.redirect = true;
      }
      const razorpay = new (window as any).RzpDefaultImpl(options);
      razorpay.open();
      razorpay.on("payment.failed", function (err: any) {
        return reject(err);
      });
    } catch (e) {
      console.error(e);
      return reject(e);
    }
  });

const displayConfigRzp = (method: RzpPaymentModes) => {
  let instruments: any = { method: method };
  if (method === "cred") {
    instruments = {
      method: "app",
      providers: ["cred"],
    };
  }
  if (method === "paypal") {
    instruments = {
      method: "wallet",
      wallets: ["paypal"],
    };
  }
  const config = {
    display: {
      blocks: {
        banks: {
          instruments: [instruments],
        },
      },
      sequence: ["block.banks"],
      preferences: {
        show_default_blocks: false,
      },
    },
  };
  return config;
};
