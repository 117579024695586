import { useTranslation } from "react-i18next";
import "../../assests/locales/i18n";

export const useLocale = () => {
  const { t, i18n } = useTranslation();
  return {
    t,
    i18n,
  };
};
